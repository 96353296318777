import './AllExceptToggle.scss';
import { useMemo } from 'react';
import classnames from 'classnames';
import { Tooltip } from 'antd';
import ByzzerToggle from '@/components/form/ByzzerToggle';
import OutlinedCheckBoxIcon from './OutlinedCheckBoxIcon';
import OutlinedCrossBoxIcon from './OutlinedCrossBoxIcon';

const baseClassName = 'all-except-toggle';

type SelectionMode = 'regular' | 'except';

export interface AllExceptToggleProps {
    value: SelectionMode;
    onChange: (value: SelectionMode) => void;
    className?: string;
    disabled?: boolean;
}

export const AllExceptToggle: React.FC<AllExceptToggleProps> = ({ value, onChange, className, disabled = false }) => {
    const handleChange = (newValue: string) => {
        onChange(newValue as SelectionMode);
    };

    const tip = useMemo(() => {
        if (value === 'except') {
            return 'Click toggle to enter regular selection mode.';
        }
        if (disabled) {
            return 'You cannot exclude All values from your selections.';
        }
        return 'Click toggle to enter all except selection mode.';
    }, [value, disabled]);

    return (
        <div className={classnames(baseClassName, className)}>
            <Tooltip placement="topLeft" title={tip} overlayClassName={`${baseClassName}__tooltip`}>
                <div>
                    <ByzzerToggle
                        className={classnames(baseClassName, {
                            [`${baseClassName}--disabled`]: disabled,
                            [`${baseClassName}--except`]: value === 'except',
                        })}
                        leftOption={{
                            display: <OutlinedCheckBoxIcon size={30} />,
                            value: 'regular',
                        }}
                        rightOption={{
                            display: <OutlinedCrossBoxIcon size={30} />,

                            value: 'except',
                        }}
                        value={value}
                        onChange={handleChange}
                    />
                </div>
            </Tooltip>
        </div>
    );
};

export default AllExceptToggle;
