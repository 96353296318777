import './InvitationSignUp.scss';
import React, {useEffect, useState} from 'react';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {ByzzerCheckbox, ByzzerInput, ByzzerLink} from '@/components/form';
import {SwitchToSignUp} from '@/components/SwitchTo';
import {alert, openErrorModal, openModal} from '@/components/form';
import {useTenantApi} from '@/hooks/useTenantApi';
import {openTermsAndConditions} from '@/components/modals/TermsAndConditions';
import classnames from "classnames";
import ByzzerPhoneInput from "@/components/form/ByzzerPhoneInput";
import {ByzzerMask} from "@/components/ByzzerMask/ByzzerMask";
import {PhoneNumberUtil} from 'google-libphonenumber';
import PasswordValidations, {findPasswordErrors} from "@/components/PasswordValidations/PasswordValidations";
import { ByzzerButton } from '@byzzer/ui-components';
import { Spinner } from '@/components/Spinner/Spinner';

const phoneUtil = PhoneNumberUtil.getInstance();

const baseClassName = 'invitation-sign-up';

export function InvitationSignUp() {
    const {acceptInvitation, getInvitationDetails} = useTenantApi();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams()
    // get the invitation id from the url param, if not use the value from the querystring
    const {invitationId = searchParams.get('invitationId')} = useParams();
    const [loaded, setLoaded] = useState(false);
    const [signUpData, setSignUpData] = useState<{
        firstName: string,
        lastName: string,
        email: string,
        phone: string,
        password: string,
        companyName: string,
        acceptedTcs: boolean,
        marketingEmailConsent: boolean,
        invitationId: string,
    }>({ 
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        password: '',
        companyName: '',
        acceptedTcs: false,
        marketingEmailConsent: true,
        invitationId: '',
     });
    const [verifyPassword, setVerifyPassword] = useState();
    const [busy, setBusy] = useState(false);

    useEffect(() => {
        if (invitationId) {
            loadInvitation(invitationId);
        }
    }, [invitationId]);

    async function loadInvitation(invitationId) {
        try {
            setBusy(true);
            setLoaded(false);
            const invitationDetails = await getInvitationDetails(invitationId);
            setSignUpData({
                ...signUpData,
                ...invitationDetails,
                invitationId,
            });
            setLoaded(true);
        } catch (err: any) {
            switch (err.code) {
                case 'invitation_not_found':
                    await openErrorModal({
                        title: `Invitation Not Found`,
                        content: (
                            <>
                                <p>We couldn't find an invitation matching this link.</p>
                                <p>Please make sure you're using the exact link you received.</p>
                                <p>If you need additional assistance please contact our support team.</p>
                            </>
                        ),
                        errorId: err.id
                    });
                    break;
                default:
                    await openErrorModal({
                        title: `Something Unexpected Happened`,
                        content: (
                            <>
                                <p>We ran into an issue loading your invitation.</p>
                                <p>But fear not our tech team is looking into the issue.</p>
                                <p>If you need additional help please contact our support team.</p>
                            </>
                        ),
                        errorId: err.id,
                    });
            }
            navigate('/auth/sign_up');
        } finally {
            setBusy(false);
        }
    }

    function onVerifyPasswordChange({target}) {
        setVerifyPassword(target.value);
    }

    function canSave() {
        const hasFirstName = Boolean(signUpData.firstName?.trim?.());
        const hasLastName = Boolean(signUpData.lastName?.trim?.())
        const hasValidPhone = isValidPhoneNumber(signUpData.phone);
        const hasValidPassword = !findPasswordErrors(signUpData.password);

        return Boolean(
            hasFirstName &&
            hasLastName &&
            hasValidPhone &&
            hasValidPassword &&
            signUpData.acceptedTcs
        );
    }

    function handleDisabledClick() {
        const {password, firstName, lastName, phone} = signUpData;
        const errors: string[] = [];

        if (!firstName.trim()) {
            errors.push(`You did not enter your first name.`);
        }

        if (!lastName.trim()) {
            errors.push(`You did not enter your last name.`);
        }

        if (!isValidPhoneNumber(phone)) {
            errors.push('Double check your phone number.  It must be a valid US or international number.')
        }

        // Validate Password
        if (findPasswordErrors(password) || password !== verifyPassword) {
            errors.push('The password you chose is not valid. Be sure to check the password criteria.')
        }

        if (password !== verifyPassword) {
            errors.push('The "Password" and "Verify Password" must match.')
        }

        let titleSuffix;
        switch (errors.length) {
            case 1:
                titleSuffix = 'An Issue';
                break;
            case 2:
                titleSuffix = 'A Couple Of Issues';
                break;
            default:
                titleSuffix = 'A Few Issues';
        }
        alert({
            type: 'error',
            className: `${baseClassName}-validation-popup`,
            title: `We Found ${titleSuffix}`,
            content: <ul className={`${baseClassName}-validation-popup__errors`}>
                {errors.map(error => <li key={error} className={`${baseClassName}-validation-popup__error`}>{error}</li>)}
            </ul>
        })
    }

    function handleLaunchByzzerClick() {
        navigate('/onboarding');
    }

    async function onSignUpClick() {
        if (busy || !canSave()) return;
        try {
            setBusy(true);
            await acceptInvitation({
                firstName: signUpData.firstName,
                lastName: signUpData.lastName,
                phone: signUpData.phone.replace(/\D/g, ''),
                password: signUpData.password,
                acceptedTcs: signUpData.acceptedTcs,
                invitationId: signUpData.invitationId,
                marketingEmailConsent: signUpData.marketingEmailConsent
            });
            await openModal({
                title: `Thank you!`,
                content: (
                    <div className={classnames(`${baseClassName}__completion-modal`)}>
                        <p>Thank you for creating an account.</p>
                        <p>You have been added to {signUpData.companyName}.</p>
                        <ByzzerButton
                            label={'Launch Byzzer'}
                            onClick={handleLaunchByzzerClick}
                        />
                    </div>
                ),
                showCloseOption: false,
            });
        } catch (err) {
            onError(err);
        } finally {
            setBusy(false);
        }
    }

    async function onError(err) {
        switch (err.code) {
            case 'company_not_found':
                // todo: add support for choosing a different company
                return openErrorModal({
                    title: `Well That's Not Good`,
                    content: (
                        <>
                            <p>Not sure how this happened, but the company you chose doesn't exist in our system.</p>
                        </>
                    ),
                    errorId: err.id
                });
            case 'invalid_password':
                // todo: add support for correcting the password and resubmitting
                return openErrorModal({
                    title: `We Want To Pump You Up!`,
                    content: (
                        <>
                            <p>
                                Well maybe not you, but your password. The password you chose is too weak. Please make
                                sure you included at least one number, capital letter and a special character. Oh and it
                                has to be at least 12 characters long but no more than 21. Because who really needs more
                                than 21 characters in a password?
                            </p>
                        </>
                    ),
                });
            case 'invalid_phone_number':
                // todo: add support for correcting the phone number and resubmitting
                return openErrorModal({
                    title: `We Want To Call You Maybe`,
                    content: (
                        <>
                            <p>But we can't because the phone number you entered isn't one that we can understand.</p>
                        </>
                    ),
                });
            case 'domain_restricted.competitor':
                return openErrorModal({
                    title: `We've Got Bad News`,
                    content: (
                        <>
                            <p>Sorry, we're limiting the companies that can sign up for Byzzer at this time.</p>
                            <p>If you'd like more information please contact customer service.</p>
                        </>
                    ),
                    errorId: err.id
                });
            case 'domain_restricted.generic':
                return openErrorModal({
                    title: `Please Use Your Work Email`,
                    content: (
                        <>
                            <p>A company email is required to sign up for Byzzer.</p>
                            <p>Please use your work email address to continue.</p>
                            <p>If you have additional questions please contact customer service.</p>
                        </>
                    ),
                });
            case 'domain_mismatch':
                return openErrorModal({
                    title: `Your Email Doesn't Match Your Company`,
                    content: (
                        <>
                            <p>
                            To keep your company's reports and data safe, we only allow accounts to be created with an email address that matches the company's domain.
                            </p>
                        </>
                    ),
                    errorId: err.id
                });
            default:
                return openErrorModal({
                    title: `Uh Oh! Something Unexpected Happened`,
                    content: (
                        <>
                            <p>Fear not our engineering team is on the job.</p>
                        </>
                    ),
                    errorId: err.id
                });
        }
    }


    function onInputChange({target}) {
        setSignUpData((state) => ({
            ...state,
            [target.name]: target.value?.trim() ?? '',
        }));
    }

    function onCheckboxChange({target}) {
        setSignUpData((state) => ({
            ...state,
            [target.name]: target.checked,
        }));
    }

    const renderVerifyPasswordValidator = () => {
        const hasValue = Boolean(verifyPassword);
        const passwordMatch = signUpData.password === verifyPassword;
        return (
            <div className={classnames(`${baseClassName}__validation`, {
                [`${baseClassName}__validation--${passwordMatch}`]: hasValue
            })}>
                Passwords Match
            </div>
        );
    };

    /**
     *
     * @param {string} strNumber Input Mobile Number as string
     * @returns
     */
    const isValidPhoneNumber = (rawValue) => {
        try {
            const phoneNumber = phoneUtil.parse(rawValue);
            return phoneUtil.isValidNumber(phoneNumber);
        } catch (err) {
            return false;
        }
    };

    function renderPhoneValidator() {
        const hasValue = !['+1', '+'].includes(signUpData.phone);
        const isValid = isValidPhoneNumber(signUpData.phone);
        const message = isValid ? 'Valid Number' : 'Enter A Valid Phone Number';
        return (
            <div className={`${baseClassName}__validations`}>
                <div className={classnames(`${baseClassName}__validation`, {
                    [`${baseClassName}__validation--${isValid}`]: hasValue
                })}>
                    {message}
                </div>
            </div>
        );
    }

    return (
        <div className={classnames(`${baseClassName}`, {
            [`${baseClassName}--loading`]: !loaded
        })}>
            <ByzzerMask loading={!loaded}>Loading Invitation</ByzzerMask>
            <header className={`${baseClassName}__header`}>
                <h1 className={`${baseClassName}__title`}>Welcome to Byzzer</h1>
                <h2 className={`${baseClassName}__message`}>Join {signUpData.companyName}</h2>
            </header>
            <main className={`${baseClassName}__content`}>
                {/* @ts-ignore */}
                <ByzzerInput
                    className={`${baseClassName}__input ${baseClassName}__firstname`}
                    label="First Name"
                    name={'firstName'}
                    value={signUpData.firstName}
                    validate={false}
                    onChange={onInputChange}
                    placeholder={'John'}
                    required
                    variant={'secondary'}
                />
                {/* @ts-ignore */}
                <ByzzerInput
                    className={`${baseClassName}__input ${baseClassName}__lastname`}
                    label="Last Name"
                    name={'lastName'}
                    value={signUpData.lastName}
                    validate={false}
                    onChange={onInputChange}
                    placeholder={'Doe'}
                    required
                    variant={'secondary'}
                />
                {/* @ts-ignore */}
                <ByzzerInput
                    className={`${baseClassName}__input ${baseClassName}__email`}
                    label="Work Email Address"
                    name={'email'}
                    value={signUpData.email}
                    locked
                    disabled
                    variant={'secondary'}
                />
                <div className={`${baseClassName}__input-group ${baseClassName}__phone`}>
                    {/* @ts-ignore */}
                    <ByzzerPhoneInput
                        label="Phone Number"
                        className={`${baseClassName}__input ${baseClassName}__phone`}
                        name={'phone'}
                        value={signUpData.phone}
                        validate={false}
                        onChange={onInputChange}
                        placeholder={'404-475-8166'}
                        required
                    />
                    {renderPhoneValidator()}
                </div>
                <div className={`${baseClassName}__input-group ${baseClassName}__password`}>
                    {/* @ts-ignore */}
                    <ByzzerInput
                        label="Password"
                        className={`${baseClassName}__input ${baseClassName}__password`}
                        type={'password'}
                        name={'password'}
                        validate={false}
                        value={signUpData.password}
                        onChange={onInputChange}
                        placeholder={'Password'}
                        required
                        variant={'secondary'}
                    />
                    {/* @ts-ignore */}
                    <PasswordValidations password={signUpData.password}/>
                </div>

                <div className={`${baseClassName}__input-group ${baseClassName}__verify-password`}>
                    {/* @ts-ignore */}
                    <ByzzerInput
                        label="Verify Password"
                        className={`${baseClassName}__input ${baseClassName}__verify-password`}
                        type={'password'}
                        name={'verifyPassword'}
                        validate={false}
                        value={verifyPassword}
                        onChange={onVerifyPasswordChange}
                        placeholder={'Password'}
                        required
                        variant={'secondary'}
                    />
                    {renderVerifyPasswordValidator()}
                </div>
                {/* @ts-ignore */}
                <ByzzerCheckbox
                    className={`${baseClassName}__input ${baseClassName}__terms`}
                    label={<>
                        <p>Accept Terms & Conditions</p>
                        <ByzzerLink
                            label={'See Details'}
                            onClick={openTermsAndConditions}/>
                    </>}
                    name={'acceptedTcs'}
                    checked={signUpData.acceptedTcs}
                    onChange={onCheckboxChange}
                />
                {/* @ts-ignore */}
                <ByzzerCheckbox
                    className={`${baseClassName}__input ${baseClassName}__consent`}
                    label={
                        <>
                            <p className={`${baseClassName}__marketing-email-consent`}>
                                By checking this box, you consent to receiving marketing & product
                                information emails from NielsenIQ.
                            </p>
                        </>
                    }
                    name={'marketingEmailConsent'}
                    checked={signUpData.marketingEmailConsent}
                    onChange={onCheckboxChange}
                />
            </main>
            <div className={classnames(`${baseClassName}__actions`)}>
                {busy && <Spinner />}
                {!busy && (
                    <>
                        <ByzzerButton
                            disabled={!canSave()}
                            label={busy ? 'Loading...' : 'Create account'}
                            onClick={onSignUpClick}
                            onDisabledClick={handleDisabledClick}
                        />
                        <SwitchToSignUp/>
                    </>
                )}
            </div>
        </div>
    );
}

export default InvitationSignUp;
