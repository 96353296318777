interface OutlinedCheckBoxIconProps {
    color?: string;
    size?: number;
}

const OutlinedCheckBoxIcon: React.FC<OutlinedCheckBoxIconProps> = ({ color = '#008A28', size = 20 }) => {
    const strokeWidth = size / 15;
    const padding = size / 5;
    const cornerRadius = size / 10;
    const pathPoints = `
        M${padding + cornerRadius} ${size / 2}
        L${size * 0.4} ${size - padding - cornerRadius}
        L${size - padding - cornerRadius} ${padding + cornerRadius}
    `;

    return (
        <svg width={size} height={size} xmlns="http://www.w3.org/2000/svg">
            <rect x="0" y="0" width={size} height={size} fill="transparent" stroke={color} strokeWidth={4} />
            <path
                d={pathPoints}
                fill="none"
                stroke={color}
                strokeWidth={strokeWidth}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default OutlinedCheckBoxIcon;
