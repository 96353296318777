import './ByzzerSearch.scss';
import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { useClickOutsideHandler } from '@/utils/utils';
import { DebounceInput } from 'react-debounce-input';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { ByzzerCheckbox } from './ByzzerCheckbox';
import { ByzzerRadio } from './ByzzerRadio';
import { useTenantApi } from '@/hooks/useTenantApi';
import { useUser } from '@/contexts/UserContext';

const baseClassName = 'byzzer-search';

type ByzzerSearchProps = {
    label?: string;
    name?: string;
    type?: any;
    value?: any;
    placeholder?: string;
    onChange?: (data?: any, status?: any) => void;
    options?: any[];
    brands?: any[];
    categories?: any[];
    className?: string;
    disabled?: boolean;
    expanded1?: boolean;
    showSpinner1?: boolean;
    readOnly?: boolean;
    allowClear?: boolean;
    byzzerSearchableSelectExpanded?: boolean;
    productType?: any;
    direction?: string;
    multiBrandSelect?: any;
    selectedMultiSelectOptions?: any[];
    productLevel?: any;
    maxLimit?: number;
    minLength?: number;
    maxLength?: number;
    marketType?: any;
    upcs?: any[];
    searchProps?: any;
    productSet?: any;
    remainingMarkets?: any;
    variant?: 'default' | 'primary' | 'secondary';
}


export function ByzzerSearch({
    label,
    name,
    type,
    value,
    placeholder,
    onChange,
    options = [],
    brands = undefined,
    categories = undefined,
    className = '',
    disabled = false,
    expanded1 = true,
    showSpinner1 = false,
    readOnly = false,
    allowClear = false,
    byzzerSearchableSelectExpanded = false,
    productType = '',
    direction = 'down',
    multiBrandSelect,
    selectedMultiSelectOptions = [],
    productLevel = '',
    maxLimit = Infinity, //  = false,
    minLength,
    maxLength,
    marketType = '',
    upcs,
    searchProps,
    productSet,
    remainingMarkets,
    variant = 'default',
    ...props
}: ByzzerSearchProps) {
    const { brandSearch, categorySearch, getUPCForCategories, manufacturerForCategoriesSearch, manufacturerSearch, marketSearch, parentCompanySearch, searchBrandForFocusProduct, searchBrandForProductSet, searchManufacturerForFocusProduct } = useTenantApi();
    const {categories: subscriptionCategories} = useUser();
    const ref = useRef<any>();
    const [thingStuff, setThingStuff] = useState<any[]>([]); // old names: filter, setFilter
    const [$value, setValue] = useState(value);
    const [expanded, setExpanded] = useState<boolean>(false);
    const classes = classnames(className, 'byzzer-search', {
        'byzzer-search--expanded': expanded,
    });
    const valueRef = useRef(value);
    // valueRef.current = value;

    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    let filteredOptions: any[] = [];
    useClickOutsideHandler(ref, () => {
        setExpanded(false);
    });

    const [hasError, setHasError] = useState(() => {
        return false;
    });

    const [hasWarning, setHasWarning] = useState(() => {
        return false;
    });

    const [errorMessage, setErrorMessage] = useState(() => {
        return '';
    });

    const [showSpinner, updateShowSpinner] = useState(() => {
        return false;
    });

    const UseFocus = () => {
        const htmlElRef = useRef<any>(null);
        const setFocus = () => {
            htmlElRef.current && htmlElRef.current.focus();
        };
        const setSelect = () => {
            htmlElRef.current && htmlElRef.current.select();
        };
        return [htmlElRef, setSelect];
    };

    const [inputRef, setInputFocus] = UseFocus();

    useEffect(() => {
        if (byzzerSearchableSelectExpanded) {
            // @ts-ignore
            setInputFocus();
        }
    }, [byzzerSearchableSelectExpanded]);

    function handleOptionClick(value) {
        setValue(value);
        setExpanded(false);
        updateShowSpinner(false);
        if (onChange) {
            onChange(value);
        }
    }

    const handleRemainingMarketsCheckbox = (currentOptions, market, status) => {
        let oldValue: any[] = [];
        const currentValue = JSON.parse(JSON.stringify(value));
        if (currentValue) {
            oldValue = currentValue;
        }
        if (status) {
            oldValue.push(market);
            const currentOptionIndex = currentOptions.map((e) => e.name).indexOf(market);
            if (currentOptionIndex !== -1) {
                currentOptions.splice(currentOptionIndex, 1);
            }
            if (currentOptions.length > 0) {
                const newOptions = currentOptions.map((option) => {
                    return (
                        <div key={option.name} className={'byzzer-search-option'}>
                            {/* @ts-ignore */}
                            <ByzzerCheckbox
                                label={option.name}
                                disabled={maxLimit && valueRef?.current?.length >= maxLimit}
                                onChange={(e) => {
                                    handleRemainingMarketsCheckbox(currentOptions, option, e.target.checked);
                                }}
                            />
                        </div>
                    );
                });
                setThingStuff(newOptions);
            } else {
                setThingStuff([]);
                setHasError(true);
                setErrorMessage('No match found. Please refine the search.');
            }
        } else {
            var index = oldValue.indexOf(market);
            if (index !== -1) {
                oldValue.splice(index, 1);
            }
        }
        setValue(JSON.parse(JSON.stringify(oldValue)));
        updateShowSpinner(false);
        if (onChange) {
            onChange(market, status);
        }
    };
    const handleMarketCheckboxChange = (currentOptions, market, status) => {
        let oldValue: any[] = [];
        const currentValue = JSON.parse(JSON.stringify(value));
        if (currentValue) {
            oldValue = currentValue;
        }
        if (status) {
            oldValue.push(market);
            var currentOptionIndex = currentOptions.map((e) => e.name).indexOf(market);
            if (currentOptionIndex !== -1) {
                currentOptions.splice(currentOptionIndex, 1);
            }
            if (currentOptions.length > 0) {
                const newOptions = currentOptions.map((option) => {
                    return (
                        <div key={option.name} className={'byzzer-search-option'}>
                            {/* @ts-ignore */}
                            <ByzzerCheckbox
                                label={option.name}
                                disabled={maxLimit && valueRef?.current?.length >= maxLimit}
                                onChange={(e) => {
                                    handleMarketCheckboxChange(currentOptions, option.name, e.target.checked);
                                }}
                            />
                        </div>
                    );
                });
                setThingStuff(newOptions);
            } else {
                setThingStuff([]);
                setHasError(true);
                setErrorMessage('No match found. Please refine the search.');
            }
        } else {
            var index = oldValue.indexOf(market);
            if (index !== -1) {
                oldValue.splice(index, 1);
            }
        }
        setValue(JSON.parse(JSON.stringify(oldValue)));
        updateShowSpinner(false);
        if (onChange) {
            onChange(market, status);
        }
    };

    // product & type can be brand, market, manufacuturer, upc
    const handleProductCheckboxChange = (currentOptions, product, status, type = '', singleSelect = false) => {
        let oldValue: any[] = [];
        const currentValue = JSON.parse(JSON.stringify(value));
        if (currentValue) {
            oldValue = currentValue;
        }
        if (singleSelect) {
            if (status) {
                oldValue = [product];
            } else {
                oldValue = [];
            }
        } else {
            if (status) {
                oldValue.push(product);
                let currentOptionIndex;
                if (type === 'upc') {
                    currentOptionIndex = currentOptions.map((e) => e.upc).indexOf(product);
                } else if (type === 'market') {
                    currentOptionIndex = currentOptions.map((e) => e.name).indexOf(product);
                } else {
                    currentOptionIndex = currentOptions.indexOf(product);
                }
                if (currentOptionIndex !== -1) {
                    currentOptions.splice(currentOptionIndex, 1);
                }
                if (currentOptions.length > 0) {
                    let newOptions;
                    if (type === 'upc') {
                        newOptions = currentOptions.map((option) => {
                            return (
                                <div key={option.upc} className={'byzzer-search-option'}>
                                    {/* @ts-ignore */}
                                    <ByzzerCheckbox
                                        label={option.upc}
                                        disabled={maxLimit && valueRef?.current?.length >= maxLimit}
                                        onChange={(e) => {
                                            handleProductCheckboxChange(
                                                currentOptions,
                                                option.upc,
                                                e.target.checked,
                                                'upc'
                                            );
                                        }}
                                    />
                                </div>
                            );
                        });
                    } else if (type === 'market') {
                        newOptions = currentOptions.map((option) => {
                            return (
                                <div key={option.name} className={'byzzer-search-option'}>
                                    {/* @ts-ignore */}
                                    <ByzzerCheckbox
                                        label={option.name}
                                        disabled={maxLimit && valueRef?.current?.length >= maxLimit}
                                        onChange={(e) => {
                                            handleProductCheckboxChange(
                                                currentOptions,
                                                option.name,
                                                e.target.checked,
                                                'market'
                                            );
                                        }}
                                    />
                                </div>
                            );
                        });
                    } else {
                        newOptions = currentOptions.map((option) => {
                            return (
                                <div key={option} className={'byzzer-search-option'}>
                                    {/* @ts-ignore */}
                                    <ByzzerCheckbox
                                        label={option}
                                        disabled={maxLimit && valueRef?.current?.length >= maxLimit - 1}
                                        onChange={(e) => {
                                            handleProductCheckboxChange(currentOptions, option, e.target.checked, type);
                                        }}
                                    />
                                </div>
                            );
                        });
                    }
                    setThingStuff(newOptions);
                } else {
                    checkForError(oldValue);
                }
            } else {
                var index = oldValue.indexOf(product);
                if (index !== -1) {
                    oldValue.splice(index, 1);
                }
            }
        }
        setValue(JSON.parse(JSON.stringify(oldValue)));
        updateShowSpinner(false);
        if (onChange) {
            onChange(product, status);
        }
    };

    const checkForError = (arr) => {
        if (arr?.length > 0) {
            setHasWarning(true);
            setHasError(false);
            setErrorMessage('There are no more matches found. Refine the search to add more.');
        } else {
            setHasError(true);
            setHasWarning(false);
            setErrorMessage('No match found. Please refine the search.');
        }
        setThingStuff([]);
    };

    async function searchForValue(value) {
        if (value.length > 2) {
            updateShowSpinner(true);
            switch (type) {
                case 'brandSearch':
                    searchForBrand(value);
                    break;
                case 'brandSearchMultiSelect':
                    searchForMultipleBrand(value);
                    setErrorMessage('');
                    setThingStuff([]);
                    break;
                case 'categorySearch':
                    searchForCategory(value);
                    break;
                case 'searchBrandForProductSet':
                    searchForProductSet(value);
                    break;
                case 'searchItemForFocusProduct':
                    searchForMultipleProductSet(value, 'omni', productLevel);
                    setErrorMessage('');
                    setThingStuff([]);
                    break;
                case 'searchBrandForProductSetMultiSelect':
                    searchForMultipleProductSet(value);
                    setErrorMessage('');
                    setThingStuff([]);
                    break;
                case 'manufacturersSearch':
                    searchForManufacturer(value);
                    break;
                case 'manufactureFromCategoriesSearch':
                    searchForManufacturerFromCategories(value);
                    break;
                case 'parentCompanySearch':
                    searchForParentCompany(value);
                    break;
                case 'marketSingleSearch':
                    setHasError(false);
                    setErrorMessage('');
                    setThingStuff([]);
                    searchForSingleMarket(value);
                    break;
                case 'ppgSearch':
                    onChange?.(value);
                    setExpanded(expanded1);
                    updateShowSpinner(showSpinner1);
                    break;
                case 'marketSearch':
                    setHasError(false);
                    setErrorMessage('');
                    setThingStuff([]);
                    searchForMarket(value);
                    break;
                case 'upcCode':
                    setHasError(false);
                    setErrorMessage('');
                    setThingStuff([]);
                    searchForUPC(value);
                    break;
                case 'upcCodeMultiSelect':
                    setHasError(false);
                    setErrorMessage('');
                    setThingStuff([]);
                    searchForMultiSelectUPC(value);
                    break;
                case 'genericSearch':
                    onChange?.(value);
                    setExpanded(expanded1);
                    updateShowSpinner(showSpinner1);
                    break;
                case 'zipCodes':
                    searchForZips(value.toString(), searchProps?.productSet);
                    setExpanded(expanded1);
                    updateShowSpinner(showSpinner1);
                    break;
                default:
                    break;
            }
        } else {
            onChange?.('');
            setExpanded(false);
            setThingStuff([]);
            setValue('');
        }
    }

    async function searchForMultipleProductSet(searchTerm, type = 'default', searchFor = '') {
        let searchResp;
        if (type !== 'default') {
            searchResp =
                searchFor?.toLowerCase() === 'brand'
                    ? await searchBrandForFocusProduct({
                          categories: productSet ?? [],
                          searchTerm
                      })
                    : await searchManufacturerForFocusProduct({
                          categories: productSet ?? [],
                          searchTerm
                      });
        } else {
            searchResp = await searchBrandForProductSet({
                categories: searchProps.productSet ?? [],
                searchTerm,
            });
        }
        updateShowSpinner(false);
        options =
            type !== 'default' && searchFor?.toLowerCase() !== 'brand' ? searchResp?.manufacturers : searchResp?.brands;
        if (options?.length === 0) {
            checkForError(selectedMultiSelectOptions);
        } else if (searchResp.has_more_hits) {
            setHasError(true);
            setErrorMessage('Showing top brands. Please refine the search.');
        } else {
            let brands: any[] = [];
            brands = options.filter((el) => !value.includes(el));
            if (brands.length > 0) {
                filteredOptions = brands.map((option) => {
                    return (
                        <div key={option} className={'byzzer-search-option'}>
                            {(productType !== 'story' || (productType === 'story' && multiBrandSelect)) && (
                                // @ts-ignore
                                <ByzzerCheckbox
                                    label={option}
                                    disabled={maxLimit && valueRef?.current?.length >= maxLimit}
                                    onChange={(e) => {
                                        handleProductCheckboxChange(brands, option, e.target.checked, 'brand');
                                    }}
                                />
                            )}
                            {productType === 'story' && !multiBrandSelect && (
                                // @ts-ignore
                                <ByzzerRadio
                                    name={'story_single_brand_selection'}
                                    onChange={(e) => {
                                        handleProductCheckboxChange(brands, option, e.target.checked, 'brand', true);
                                    }}
                                    label={option}
                                />
                            )}
                        </div>
                    );
                });
            } else {
                setHasError(true);
                setErrorMessage('No match found. Please refine your search.');
                setThingStuff([]);
            }
            setThingStuff(filteredOptions);
        }
    }

    async function searchForProductSet(searchText) {
        let searchResp;
        searchResp = await searchBrandForProductSet({
            categories: productSet ?? [],
            input_text: searchText,
        });
        updateShowSpinner(false);
        const brandOptions = searchResp?.brands;
        filteredOptions =
            brandOptions !== undefined
                ? brandOptions.map((option) => {
                      const { value = option, display = option } = option;
                      return (
                          <div key={value} className={'byzzer-search-option'} onClick={() => handleOptionClick(option)}>
                              {display}
                          </div>
                      );
                  })
                : [];
        setThingStuff(filteredOptions);
        if (filteredOptions.length === 0) {
            setThingStuff(filteredOptions);
            setHasError(true);
            setErrorMessage('No match found. Please refine the search.');
        } else if (searchResp.has_more_hits) {
            setHasError(true);
            setErrorMessage('Showing top brands. Please refine the search.');
        } else {
            setThingStuff(filteredOptions);
            if (filteredOptions.length === 0) {
                setThingStuff(filteredOptions);
                setHasError(true);
                setErrorMessage('No match found. Please refine the search.');
            } else if (searchResp.has_more_hits) {
                setHasError(true);
                setErrorMessage('Showing top brands. Please refine the search.');
            } else {
                setThingStuff(filteredOptions);
                setHasError(false);
                setErrorMessage('');
            }
        }
    }

    async function searchForCategory(searchText: string) {
        const categoryList = await categorySearch({searchTerm: encodeURIComponent(searchText)});
        updateShowSpinner(false);
        options = categoryList?.data?.categories;
        const error = categoryList?.data?.error;
        filteredOptions =
            options !== undefined
                ? options.map((option) => {
                      const { value = option, display = option } = option;
                      return (
                          <div key={value} className={'byzzer-search-option'} onClick={() => handleOptionClick(option)}>
                              {display}
                          </div>
                      );
                  })
                : [];
        if (filteredOptions.length === 0) {
            setThingStuff(filteredOptions);
            setHasError(true);
            setErrorMessage(error);
        } else if (categoryList.data.has_more_hits) {
            setHasError(true);
            setErrorMessage('Showing top categories. Please refine the search.');
        } else {
            setThingStuff(filteredOptions);
            setHasError(false);
            setErrorMessage('');
        }
    }

    const searchForZips = async (searchText, allZipsToSearch) => {
        const zipOptions = allZipsToSearch.filter((zip) => {
            return zip.search(searchText.toString()) > -1;
        });
        let zips = [];
        zips = zipOptions.filter((el) => !value.includes(el));
        if (zips.length > 0) {
            filteredOptions = zips.map((foundZip) => {
                return (
                    <div key={foundZip} className={'byzzer-search-option'}>
                        {/* @ts-ignore */}
                        <ByzzerCheckbox
                            label={foundZip}
                            onChange={(e) => {
                                handleProductCheckboxChange(zips, foundZip, e.target.checked, 'zip');
                            }}
                            disabled={maxLimit && value.length >= maxLimit}
                        />
                    </div>
                );
            });
        }
        setThingStuff(filteredOptions);
    };
    async function searchForBrand(searchText: string) {
        const brandList = await brandSearch({
            searchTerm: encodeURIComponent(searchText),
            ...(categories?.length ? { categories } : {}),
        });
        updateShowSpinner(false);
        options = brandList?.brands;
        const error = brandList?.error;
        filteredOptions =
            options !== undefined
                ? options.map((option) => {
                      const { value = option, display = option } = option;
                      return (
                          <div key={value} className={'byzzer-search-option'} onClick={() => handleOptionClick(option)}>
                              {display}
                          </div>
                      );
                  })
                : [];
        setThingStuff(filteredOptions);
        if (filteredOptions.length === 0) {
            setThingStuff(filteredOptions);
            setHasError(true);
            setErrorMessage(error);
        } else if (brandList?.has_more_hits) {
            setHasError(true);
            setErrorMessage('Showing top brands. Please refine the search.');
        } else {
            setThingStuff(filteredOptions);
            setHasError(false);
            setErrorMessage('');
        }
    }

    async function searchForMultipleBrand(searchText: string) {
        const brandList = await brandSearch({
            searchTerm: encodeURIComponent(searchText),
            ...(categories?.length ? { categories } : {}),
        });
        updateShowSpinner(false);
        options = brandList?.brands;
        const error = brandList?.error;

        if (error !== undefined && Object.keys(error).length > 0) {
            setHasError(true);
            setErrorMessage(error);
            setThingStuff([]);
        } else if (error === undefined && options.length === 0) {
            setHasError(true);
            setErrorMessage('No match found. Please refine your search.');
            setThingStuff([]);
        } else {
            let brands: any[] = [];
            brands = options.filter((el) => !value.includes(el));
            if (brands.length > 0) {
                filteredOptions = brands.map((option) => {
                    return (
                        <div key={option} className={'byzzer-search-option'}>
                            {/* @ts-ignore */}
                            <ByzzerCheckbox
                                label={option}
                                onChange={(e) => {
                                    handleProductCheckboxChange(brands, option, e.target.checked, 'brand');
                                }}
                                disabled={maxLimit && valueRef?.current?.length >= maxLimit}
                            />
                        </div>
                    );
                });
            } else {
                setHasError(true);
                setErrorMessage('No match found. Please refine your search.');
                setThingStuff([]);
            }
            setThingStuff(filteredOptions);
        }
    }

    const buildMarketList = (marketsList) => {
        const searchResultMarketsTemp: any[] = [];
        const marketSearchResultsTemp = marketsList;
        if (marketSearchResultsTemp.length > 0) {
            marketSearchResultsTemp.forEach((market) => {
                searchResultMarketsTemp.push({
                    name: market.name,
                    marketSelType: market.mrkt_sel_typ,
                    remainingCompStores: market.rm_channel_mrkt_disp_name,
                    remainingCompRetailers: market.rm_xaoc_mrkt_disp_name,
                });
            });
        }
        return searchResultMarketsTemp;
    };

    async function searchForMarket(searchText) {
        let requestBody = {};
        requestBody = categories !== undefined ? (categories.length > 0 ? { categories: categories } : {}) : {};
        const res = await marketSearch({
            searchTerm: searchText,
            marketType: 'RMS',
            reportType: 'subscription',
            categories: categories?.length ? categories : undefined,
            embodyTotalMarkets: true,
            embodyFmcgRetailers: true,
            embodySpecialityRetailers: true,
            embodyGeographyMarkets: true,
            subMarketType: marketType,
            //...props?.subSelectors?.markets?.marketSections
        });
        updateShowSpinner(false);

        if (res.data.error !== undefined && Object.keys(res.data.error).length > 0) {
            setHasError(true);
            setErrorMessage(res.data.error);
            setThingStuff([]);
        } else if (res.data.folders !== undefined && res.data.folders.length === 0) {
            setHasError(true);
            setErrorMessage('No match found. Please refine your search.');
            setThingStuff([]);
        } else {
            let markets = buildMarketList(res.data);
            let options: any[] = [];
            let valueName: any[] = [];
            if (value?.length > 0) value?.forEach((item) => valueName.push(item.name));
            if (remainingMarkets) {
                options = markets.filter((el) => !valueName.includes(el.name));
            } else {
                options = markets.filter((el) => !value.includes(el.name));
            }
            if (options.length > 0) {
                filteredOptions = options.map((option) => {
                    return (
                        <div key={option.name} className={'byzzer-search-option'}>
                            {/* @ts-ignore */}
                            <ByzzerCheckbox
                                label={option.name}
                                disabled={maxLimit && valueRef?.current?.length >= maxLimit}
                                onChange={(e) => {
                                    handleProductCheckboxChange(options, option.name, e.target.checked, 'market');
                                }}
                            />
                        </div>
                    );
                });
            } else {
                setHasError(true);
                setErrorMessage('No match found. Please refine your search.');
                setThingStuff([]);
            }
            setThingStuff(filteredOptions);
        }
    }

    async function searchForSingleMarket(searchText) {
        let requestBody = {};
        requestBody = categories !== undefined ? (categories.length > 0 ? { categories: categories } : {}) : {};
        const res = await marketSearch({
            searchTerm: searchText,
            marketType: 'RMS',
            reportType: 'subscription',
            categories: categories?.length ? categories : undefined,
            embodyTotalMarkets: true,
            embodyFmcgRetailers: true,
            embodySpecialityRetailers: true,
            embodyGeographyMarkets: true,
            //...props?.subSelectors?.markets?.marketSections
        });
        updateShowSpinner(false);

        if (res.data.error !== undefined && Object.keys(res.data.error).length > 0) {
            setHasError(true);
            setErrorMessage(res.data.error);
            setThingStuff([]);
        } else if (res.data.folders !== undefined && res.data.folders.length === 0) {
            setHasError(true);
            setErrorMessage('No match found. Please refine your search.');
            setThingStuff([]);
        } else {
            updateShowSpinner(false);
            options = buildMarketList(res.data);
            filteredOptions =
                options !== undefined
                    ? options.map((option) => {
                          return (
                              <div
                                  key={option.name}
                                  className={'byzzer-search-option'}
                                  onClick={() => handleOptionClick(remainingMarkets ? option : option.name)}
                              >
                                  {option.name}
                              </div>
                          );
                      })
                    : [];
            setThingStuff(filteredOptions);
        }
    }

    async function searchForUPC(searchText) {
        let requestBodyBrands,
            requestBody;
        requestBodyBrands = brands !== undefined ? (brands.length > 0 ? { brands: brands } : []) : [];
        requestBody = categories !== undefined ? (categories.length > 0 ? { categories: categories } : []) : [];
        const res = await getUPCForCategories(
            encodeURIComponent(searchText),
            requestBodyBrands?.brands,
            requestBody?.categories
        );
        updateShowSpinner(false);
        // @ts-ignore
        if (searchText.length >= 5 && res.upc_details) {
            // @ts-ignore
            const upcOptions = res?.upc_details;
            filteredOptions =
                upcOptions !== undefined
                    ? upcOptions.map((option) => {
                          const { value = option.upc, display = option.upc } = option;
                          return (
                              <div
                                  key={value}
                                  className={'byzzer-search-option'}
                                  onClick={() => handleOptionClick(option.upc)}
                              >
                                  {display}
                              </div>
                          );
                      })
                    : [];
            setThingStuff(filteredOptions);

            if (upcOptions.length === 0) {
                setHasError(true);
                setErrorMessage('No match found. Please refine your search.');
            }
        } else {
            setHasError(true);
            // @ts-ignore
            setErrorMessage(res?.error);
        }
    }

    async function searchForMultiSelectUPC(searchText) {
        let requestBodyBrands,
            requestBody;
        requestBodyBrands = brands !== undefined ? (brands.length > 0 ? { brands: brands } : []) : [];
        requestBody = categories !== undefined ? (categories.length > 0 ? { categories: categories } : []) : [];
        const res = await getUPCForCategories(
            encodeURIComponent(searchText),
            requestBodyBrands?.brands,
            requestBody?.categories
        );
        updateShowSpinner(false);
        // @ts-ignore
        options = res?.upc_details;
        // @ts-ignore
        const error = res.error;

        if (error !== undefined && Object.keys(error).length > 0) {
            setHasError(true);
            setErrorMessage(error);
            setThingStuff([]);
        } else if (error === undefined && options.length === 0) {
            setHasError(true);
            setErrorMessage('No match found. Please refine your search.');
            setThingStuff([]);
        } else {
            let filteredUpcs: any[] = [];
            if (upcs && upcs.length > 0) {
                filteredUpcs = options.filter((el) => !upcs.includes(el.upc));
            } else {
                filteredUpcs = options.filter((el) => !value.includes(el.upc));
            }
            if (filteredUpcs.length > 0) {
                filteredOptions = filteredUpcs.map((option) => {
                    return (
                        <div key={option.upc} className={'byzzer-search-option'}>
                            {/* @ts-ignore */}
                            <ByzzerCheckbox
                                label={option.upc}
                                disabled={maxLimit && valueRef?.current?.length >= maxLimit}
                                onChange={(e) => {
                                    handleProductCheckboxChange(filteredUpcs, option.upc, e.target.checked, 'upc');
                                }}
                            />
                        </div>
                    );
                });
            } else {
                setHasError(true);
                setErrorMessage('No match found. Please refine your search.');
                setThingStuff([]);
            }
            setThingStuff(filteredOptions);
        }
    }

    function onFilterChange({ target }) {
        if (type === 'elasticSearch') {
            setExpanded(false);
        } else {
            setExpanded(expanded1);
            searchForValue(target.value);
        }
    }

    function onFilterClick() {
        if (type === 'elasticSearch') onChange?.({ openElasticSearch: true });
    }

    async function searchForManufacturer(searchText) {
        const manufacturerList = await manufacturerSearch(encodeURIComponent(searchText));
        updateShowSpinner(false);
        options = manufacturerList?.manufacturers;
        const error = manufacturerList?.error;
        filteredOptions =
            options !== undefined
                ? options.map((option) => {
                      const { value = option, display = option } = option;
                      return (
                          <div key={value} className={'byzzer-search-option'} onClick={() => handleOptionClick(option)}>
                              {display}
                          </div>
                      );
                  })
                : [];
        setThingStuff(filteredOptions);
        if (filteredOptions.length === 0) {
            setThingStuff(filteredOptions);
            setHasError(true);
            setErrorMessage(error);
        } else if (manufacturerList?.has_more_hits) {
            setHasError(true);
            setErrorMessage('Showing top manufacturers. Please refine the search.');
        } else {
            setThingStuff(filteredOptions);
            setHasError(false);
            setErrorMessage('');
        }
    }

    const searchForManufacturerFromCategories = async (searchText: string) => {
        let categoriesList: typeof subscriptionCategories = [];
        if (subscriptionCategories !== undefined) {
            categoriesList = subscriptionCategories || [];
        }
        let reqBody = {
            searchTerm: searchText.trim(),
            categories: categoriesList,
        };
        const manufacturerList = await manufacturerForCategoriesSearch(reqBody);
        updateShowSpinner(false);
        options = manufacturerList?.manufacturers;
        const error = manufacturerList?.error;

        if (options?.length === 0) {
            checkForError(selectedMultiSelectOptions);
        } else if (manufacturerList?.has_more_hits) {
            setHasError(true);
            setErrorMessage('Showing top manufacturers. Please refine the search.');
        } else {
            let manufacturers: any[] = [];
            manufacturers = options.filter((el) => !value.includes(el));
            if (manufacturers?.length > 0) {
                filteredOptions = manufacturers.map((option) => {
                    return (
                        <div key={option} className={'byzzer-search-option'}>
                            {productType === 'story' && !multiBrandSelect && (
                                // @ts-ignore
                                <ByzzerRadio
                                    name={'story_single_brand_selection'}
                                    onChange={(e) => {
                                        handleProductCheckboxChange(
                                            manufacturers,
                                            option,
                                            e.target.checked,
                                            'manufacturer',
                                            true
                                        );
                                    }}
                                    label={option}
                                />
                            )}
                        </div>
                    );
                });
            } else {
                setHasError(true);
                setErrorMessage('No match found. Please refine your search.');
                setThingStuff([]);
            }
            setThingStuff(filteredOptions);
        }
    };

    async function searchForParentCompany(searchText) {
        const parentCompanyList = await parentCompanySearch(encodeURIComponent(searchText));
        updateShowSpinner(false);
        options = parentCompanyList?.parent_companies;
        const error = parentCompanyList?.error;
        filteredOptions =
            options !== undefined
                ? options.map((option) => {
                      const { value = option, display = option } = option;
                      return (
                          <div key={value} className={'byzzer-search-option'} onClick={() => handleOptionClick(option)}>
                              {display}
                          </div>
                      );
                  })
                : [];
        setThingStuff(filteredOptions);
        if (filteredOptions.length === 0) {
            setThingStuff(filteredOptions);
            setHasError(true);
            setErrorMessage(error);
        } else if (parentCompanyList?.has_more_hits) {
            setHasError(true);
            setErrorMessage('Showing top parent companies. Please refine the search.');
        } else {
            setThingStuff(filteredOptions);
            setHasError(false);
            setErrorMessage('');
        }
    }

    const clearSelection = (e) => {
        if (allowClear && $value !== '') {
            e.preventDefault();
            setValue('');

            if (onChange) {
                onChange('');
            }
            // alert();
        }
    };

    return (
        <label className={classes} ref={ref}>
            {label && <div className={`${baseClassName}__label`}>{label}</div>}
            <div className={classnames(`${baseClassName}__control`, {
                [`${baseClassName}__control--${variant}`]: variant,
            })}>
                <DebounceInput
                    key={`search-${value}`}
                    minLength={minLength || 2}
                    maxLength={maxLength || 20}
                    debounceTimeout={1000}
                    onClick={onFilterClick}
                    onChange={onFilterChange}
                    inputRef={inputRef}
                    className="byzzer-search-input"
                    placeholder={type === 'marketSearch' ? placeholder : placeholder || value || $value?.display}
                    value={type === 'marketSearch' ? '' : value}
                    disabled={disabled}
                    readOnly={readOnly}
                />
                {showSpinner && (
                    <div className={'byzzer-search-spinner'}>
                        <Spin indicator={antIcon} />
                    </div>
                )}
                {allowClear && $value !== '' && (
                    <div onClick={(e) => clearSelection(e)} className={'byzzer-select__close'} />
                )}
                <div className={classnames('byzzer-search-options', `${baseClassName}__options--open-${direction}`)}>
                    {hasError && <span className={'byzzer-search__error'}>{errorMessage}</span>}
                    {hasWarning && <span className={'byzzer-search__warning'}>{errorMessage}</span>}
                    <div className={'byzzer-search-options__scrollarea'}>{thingStuff}</div>
                </div>
            </div>
        </label>
    );
}

export default ByzzerSearch;
