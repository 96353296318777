import { forwardRef, ReactNode, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { ByzzerChangeEventHandler, ByzzerSelect, WithValue, ByzzerSelectOption } from '@byzzer/ui-components';
import { useAttributeService } from '@/services/attribute.service';

export type ByzzerAttributeGroupSelectProps = {
    name?: string;
    label?: ReactNode;
    onChange?: ByzzerChangeEventHandler<string[]>;
    value?: string[];
    disabledOptions?: string[];
    placeholder?: string;
    className?: string;
    disabled?: boolean;
    maxSelections?: number;
    categories?: string[];
    aggregationLevel?: string;
    needAllAttributeGroup?:boolean;
} & OnlyRenderableIf;

export const AttributeGroupSelect = forwardRef<WithValue<string[]>, ByzzerAttributeGroupSelectProps>(
    (
        {
            onChange,
            name,
            label,
            className,
            disabledOptions = [],
            placeholder,
            value,
            disabled,
            onlyRenderIf,
            maxSelections,
            categories,
            aggregationLevel,
            needAllAttributeGroup = false,
        },
        ref
    ) => {
        const [internalValue, setInternalValue] = useState<string[]>();
        if (onlyRenderIf === false) return <></>;

        const [attributeGroupOptions, setAttributeGroupOptions] = useState<ByzzerSelectOption[]>();
        const { getAttributeGroups } = useAttributeService();

        useEffect(() => {
            setInternalValue(value);
        }, [value]);

        useEffect(() => {
            (async (categories) => {
                await loadAttributeGroupOptions(categories, aggregationLevel);
            })(categories);
        }, [categories, aggregationLevel]);

        async function loadAttributeGroupOptions(categories?: string[], aggregationLevel?: string) {
            // if (categories?.length) setAttributeGroupOptions(await getAttributeGroups(categories, aggregationLevel));

            if (categories?.length) {
                const options = await getAttributeGroups(categories, aggregationLevel);
                
                if (needAllAttributeGroup) 
                    options?.unshift({ display: 'All', value: 'All' })  
                
                setAttributeGroupOptions(options)            
            }
        }

        function handleChange(e: any) {
            onChange?.({
                value: e?.value,
                name,
                data: e?.data,
            });
            setInternalValue(e.value);
        }

        return (
            <ByzzerSelect
                ref={ref}
                name={name}
                className={classnames(className)}
                options={attributeGroupOptions}
                disabled={disabled}
                placeholder={placeholder}
                label={label as any}
                value={internalValue}
                disabledOptions={disabledOptions}
                onChange={handleChange}
                allowMultiple={true}
                maxSelections={maxSelections}
            />
        );
    }
);

export default AttributeGroupSelect;

AttributeGroupSelect.displayName = 'AttributeGroupSelect';
