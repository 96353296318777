import './DraggableProductDimension.scss';
import {ReactNode, useMemo, useState, useRef, useEffect} from "react";
import { Tooltip } from 'antd';
import {SortType} from "@/types/DodRun";
import {ByzzerCheckableChangeEvent, ByzzerCheckbox, ByzzerTipIcon} from "@byzzer/ui-components";
import {Draggable} from "react-beautiful-dnd";
import classnames from "classnames";
import {SortToggle} from "@/components/DodConfigEditor/builders/DodLayoutBuilder/DodDesignYourLayout/SortToggle";
import {RowColConfig} from "@/components/DodConfigEditor/types";
import { useDodWizard } from '@/components/DodConfigEditor/DodRunConfigWizard/DodWizardContext';
import {useEventDataWithUserInfo, useTrackEvent} from "@/analytics/AnalyticsContext";

interface DraggableProductDimensionProps {
    className?: string;
    label: ReactNode;
    config: RowColConfig;
    index: number;
    hiddenTip?: ReactNode;
    hideStack?: boolean;  
    onConfigChange?(config: RowColConfig): void;
}

const baseClassName = 'draggable-product-dimension';

function SubDimensionLabel({ label }: { label: ReactNode }) {
    const [isLabelOverflowing, setIsLabelOverflowing] = useState(false);
    const labelRef = useRef<HTMLHeadingElement>(null);

    useEffect(() => {
        if (labelRef.current) {
            setIsLabelOverflowing(labelRef.current.scrollWidth > labelRef.current.clientWidth);
        }
    }, [label]);

    return (
        <div
            className={`${baseClassName}__label ${isLabelOverflowing ? `${baseClassName}__label--overflow` : ''}`}
            ref={labelRef}
        >
            <Tooltip placement="topLeft" title={isLabelOverflowing && label} overlayClassName={`${baseClassName}__label--tooltip`}>
                {label}
            </Tooltip>
        </div>
    );
}

export function DraggableProductDimension({
                                              className,
                                              label,
                                              config,
                                              index,
                                              onConfigChange,
                                              hiddenTip,
                                              hideStack = false,
                                              ...props
                                          }: DraggableProductDimensionProps) {

    const stackable = config.axis === 'row' && !hideStack;
    const { runConfig, setRunConfig } = useDodWizard();
    const trackEvent = useTrackEvent();
    const getEventData = useEventDataWithUserInfo();
    const hasSummedSelections = getProductSummedSelectionStatus(config.dim)
    const disableHide = useMemo(() => {
        const { dim } = config;
        let disable = false;
        let tip = "";

        for (const fact of runConfig.facts) {
            if (
                (dim === 'categories' && (fact.category || fact.categoryYearAgo)) ||
                (dim === 'brands' && (fact.brand || fact.brandYearAgo)) ||
                (dim === 'upcs' && fact.isUPCrequired)
            ) {
                disable = true;
                tip = dim === 'categories'
                ? 'You cannot hide Category when you have Share of Category facts selected.'
                : dim === 'upcs'
                ? 'You cannot hide UPC when you have UPC-level facts selected.'
                : 'You cannot hide Brand when you have Share of Brand facts selected.';
                break;
            }
        }

        return { disable, tip };
    }, [runConfig, config]);

    function getProductSummedSelectionStatus(productSubDimensionType: string) {
        if (runConfig.filters[productSubDimensionType] && Boolean(runConfig.filters[productSubDimensionType].summedSelections.length)) {
            return true
        } else if (Boolean(Object.values(runConfig.filters.characteristics).find((item) => item.displayName === config.dim)?.summedSelections.length)) {
            return true
        } else if (Boolean(Object.values(runConfig.filters.customCharacteristics).find((item) => item.displayName === config.dim)?.summedSelections.length)) {
            return true
        } else if (Boolean(Object.values(runConfig.filters.ppgs).find((item) => item.displayName === config.dim)?.summedSelections.length)) {
            return true
        }else {
            return false
        }
    }

    const pageByDisabledTip = useMemo<ReactNode | undefined>(() => {
        if (config.stack) {
            return <>
                <p>A dimension cannot be Paged if it is Stacked</p>
                <p>Please uncheck Stack first.</p>
            </>
        }

        if (config.hide) {
            return <>
                <p>A hidden dimension cannot be Paged</p>
                <p>Please uncheck Hide first.</p>
            </>
        }
    }, [config.stack, config.hide]);

    const stackDisabledTip = useMemo<ReactNode | undefined>(() => {
        if (config.pageBy) {
            return <>
                <p>A dimension cannot be Stacked if it is Paged</p>
                <p>Please uncheck "Page By" first.</p>
            </>
        }

        if (config.hide) {
            return <>
                <p>A hidden dimension cannot be Stacked.</p>
                <p>Please uncheck Hide first.</p>
            </>
        }

        if (hasSummedSelections) {
            return <>
                <p>Summed products and stacked layout are currently not compatible.</p>
                <p>Please choose a different layout option.</p>
            </>
        }

    }, [config.pageBy, config.hide])

    function handleSortChange(sortType: SortType): void {
        onConfigChange?.({
            ...config,
            sortType
        })

        trackEvent({
            type: 'click',
            name: 'dod_layout_sort_click',
            data: getEventData({ dodWizardStep: 'layout', filter: config.dim, order: sortType }),
        });
    }

    function handleFlagChange(e: ByzzerCheckableChangeEvent<string>): void {
        onConfigChange?.({
            ...config,
            [e.name!]: e.checked
        })

        trackEvent({
            type: 'click',
            name: e.checked ? `dod_layout_product_${e.name}_checked` : `dod_layout_product_${e.name}_unchecked`,
            data: getEventData({ dodWizardStep: 'layout', filter: 'product', type: config.dim}),
        });
    }

    function handleHideChange(e: ByzzerCheckableChangeEvent<string>): void {
        if(config.dim === 'categories' && e.checked) {
            // when category is hidden, category totals need to reset to false and disabled
            setRunConfig({...runConfig, layout: {
                ...runConfig.layout,
                includeCategoryTotals: false
            }})
        }
        onConfigChange?.({
            ...config,
            hide: e.checked,
            pageBy: config.pageBy && !e.checked,
            stack: config.stack && !e.checked
        })

        trackEvent({
            type: 'click',
            name: e.checked ? 'dod_layout_product_hide_checked' : 'dod_layout_product_hide_unchecked',
            data: getEventData({ dodWizardStep: 'layout', filter: 'product', type: config.dim }),
        });
    }

    //BYZ-12113 - Hide and stack option should be disabled always if any of the market in run is partially-approved
    const hasPartiallyApprovedMarkets = useMemo(() => {
        if (
            config.dim === 'categories' &&
            runConfig.filters.markets.values.some((item) => item.marketHasPartialApproval === true)
        ) {
            onConfigChange?.({
                ...config,
                hide: false,
                stack: false,
            });
            return true;
        } else {
            return false;
        }
    }, [runConfig.filters.markets.values, config.dim]);

    return (
        <Draggable
            isDragDisabled={false}
            draggableId={config.dim}
            index={index}
        >
            {(provided, snapshot) => (
                <div
                    className={classnames(baseClassName, className)} {...props}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <i className={`${baseClassName}__dnd-handle`}/>
                    <SortToggle value={config.sortType} onChange={handleSortChange}
                                manualTip={<>
                                    <p>Sorted Using Default (Ascending)</p>
                                    <p>Click to toggle.</p>
                                </>}/>
                    <SubDimensionLabel label={label} />
                    <div className={`${baseClassName}__actions`}>
                        <ByzzerCheckbox
                            checked={config.hide} name={'hide'} onChange={handleHideChange}
                            disabled={hasPartiallyApprovedMarkets || disableHide.disable}
                            disabledTip= {disableHide.disable && disableHide.tip}
                            label={<div className={`${baseClassName}__hide-label`}>
                                Hide <ByzzerTipIcon
                                tipDelay={[750, 0]}
                                tip={<div className={`${baseClassName}__hide-tip-content`}>{hiddenTip}</div>}
                                className={classnames(`${baseClassName}__hide-tip`, {
                                    [`${baseClassName}__hide-tip--exists`]: Boolean(hiddenTip)
                                })}/>
                            </div>}
                            /> 
                        {stackable && (
                            <ByzzerCheckbox checked={config.stack} name={'stack'} onChange={handleFlagChange}
                                            disabledTip={stackDisabledTip}
                                            disabled={config.hide || config.pageBy || hasPartiallyApprovedMarkets || hasSummedSelections }
                                            label={'Stack'}/>
                        )}
                        <ByzzerCheckbox checked={config.pageBy} name={'pageBy'} onChange={handleFlagChange}
                                        disabledTip={pageByDisabledTip}
                                        disabled={config.hide || config.stack}
                                        label={'Page By'}/>
                    </div>
                </div>
            )}
        </Draggable>
    );
}
