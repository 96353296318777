import './Notification.scss';
import React, {useEffect, useState} from 'react';
import classnames from 'classnames';
import warnIcon from '@images/icons/homePage/warning.svg';
import announceIcon from '@images/icons/homePage/megaPhone.svg';
import recommendIcon from '@images/icons/homePage/recommendAction.svg';
import productUpdateIcon from '@images/icons/homePage/productUpdate.svg';
import dotsEnabled from '@images/icons/homePage/dotsEnabled.svg';
import dotsDisabled from '@images/icons/homePage/dotsDisabled.svg';
import doNow from '@images/icons/homePage/notifications/doNow.svg';
import doLater from '@images/icons/homePage/notifications/doLater.svg';
import alreadyDone from '@images/icons/homePage/notifications/alreadyDone.svg';
import dismiss from '@images/icons/homePage/notifications/dismiss.svg';
import {ByzzerLink} from '@/components/form';
import {Dropdown, Menu} from 'antd';
import 'antd/lib/dropdown/style/index.css';
import {useNavigate} from 'react-router-dom';
import {useTenantApi} from '@/hooks/useTenantApi';
import {useEvents, useUser} from '@/contexts/UserContext';
import {getURLParam, splitLink} from '@/utils/homePageUtil';
import {Spinner} from '@/components/Spinner/Spinner';
import {useIntercom} from 'react-use-intercom';

const baseClassName = 'notification';
const notificationMap = {
    WARNING: {
        icon: warnIcon,
    },
    PRODUCT_UPDATE: {
        icon: productUpdateIcon,
    },
    RECOMMENDATION: {
        icon: recommendIcon,
    },
    ANNOUNCEMENT: {
        icon: announceIcon, 
    },
};


const notificationActions = [
    {
        label: 'Do Now',
        icon: doNow,
        key: 0,
    },
    {
        label: 'Already Done',
        icon: alreadyDone,
        key: 1,
    },
    {
        label: 'Do Later',
        icon: doLater,
        key: 2,
    },
    {
        label: 'Dismiss',
        icon: dismiss,
        key: 3,
    },
];
const markAsReadActions = [{
    label: 'Mark As Read',
    icon: alreadyDone,
    key: 4,
}]

const notificationHeaderActions = [
    ...notificationActions,
    ...markAsReadActions
]

export interface NotificationsProp {
    className?: string;
    category?: any;
    dashboardLoading?: any;
    componentView?: 'header' | 'home';
}


export const Notification = React.memo(({ className, category, dashboardLoading, componentView='home' }: NotificationsProp) => {
    const {getMyNotifications, updateNotification} = useTenantApi();

    const navigate = useNavigate();
    const events = useEvents();
    const { user , notifications, refreshNotifications} = useUser();
    const [visible, setVisible] = useState(false);
    const [loading, setloading] = useState(false);
    const [currentNotificationId, setcurrentNotificationId] = useState(0);
    const { startTour: startTour } = useIntercom();

    const [notificationItems, setNotificationItems] = useState<any>([]);
    
    useEffect(() => {
        initialize();
    }, []);

    useEffect(() => {
        let notificationData = notifications;
        if(componentView === 'header') {
            notificationData = notifications.filter((item)=> {
                return item.messageType.toUpperCase() !== "RECOMMENDATION"
            })

            //  BYZ-9418 - the custom sorting w.r.t isRead is needed for header notifications ONLY and this sorting shouldnot change/impact the default order for homepage coming from the backend
            notificationData = notificationData.sort((a,b) => {
                if((a.isRead === null && b.isRead !== null) || (a.isRead === false && b.isRead !== false)){
                    return -1;
                } else if ((a.isRead !== null && b.isRead === null) || (a.isRead !== false && b.isRead === false)){
                    return 1;
                } else {
                    return 0;
                }
            })            
        }        
        
        setNotificationItems(notificationData);
    }, [notifications])

    useEffect(() => {
        const [event] = events;
        if (event?.type === 'consumerMessage') {
            initialize();
        }
    }, [events]);
    async function initialize() {
        try {
            setloading(true);
            // const notifications = await getMyNotifications();
            await refreshNotifications();
            setloading(false);
        } catch (error) {
            setloading(false);
        }
    }

    const handleActionSelection = (e) => {
        setVisible(false);
    };

    function onToggleOfActionIcon(id, visState) {
        setVisible(visState);
        setcurrentNotificationId(id);
    }

    const openInNewTab = (link) => {
        if (link) {
            window.open(link, '_blank');
        }
    }
    const onClickActionItem = async (
        action,
        notificationId,
        notification
    ) => {
        onToggleOfActionIcon(notificationId, visible);
        let notificationAction = {
            id: notification?.id,
            isRead: notification.isRead,
            isDone: notification.isDone,
            readDtm: notification.readDtm,
            deletedDtm: notification.deletedDtm,
            isDeleted: notification.isDeleted,
            snoozeDtm: notification.snoozeDtm,
            inboxMessageId: !notification?.id ? notification?.notificationId : null,
            userId: !notification?.id ? user?.id : null
        };
        try {
            switch (action) {
                case 'Do Now':
                    notificationAction.isRead = true;
                    notificationAction.readDtm = new Date().toISOString();
                    if (notification?.message?.hyperLink)
                        if (notification?.message?.hyperLink?.split('#')?.length < 2) openInNewTab(notification?.message?.hyperLink)
                        else {
                            const tourId = getURLParam(notification?.message?.hyperLink, "product_tour_id");
                            if (tourId) startTour(Number(tourId))
                            navigate(splitLink(notification?.message?.hyperLink),  {state: { additionalPayload: notification?.message?.config,navigateTabIndex:3 }});
                        }
                    break;
                case 'Do Later':
                    notificationAction.isRead = true;
                    notificationAction.readDtm = new Date().toISOString();
                    notificationAction.snoozeDtm = new Date().toISOString();
                    break;
                case 'Already Done':
                    notificationAction.isRead = true;
                    notificationAction.isDone = true;
                    notificationAction.readDtm = new Date().toISOString();
                    notificationAction.isDeleted = true;
                    notificationAction.deletedDtm = new Date().toISOString();
                    break;
                case 'Dismiss':
                    notificationAction.isDeleted = true;
                    notificationAction.deletedDtm = new Date().toISOString();
                    break;
                case 'Mark As Read':
                    notificationAction.isRead = true;
                    notificationAction.readDtm = new Date().toISOString();
                    break;
                default:
                    break;
            }
            await updateNotification(notificationAction);
            initialize();
        } catch (error) {
            console.log('error', error);
        }
    };
    // each action item in notification action menu
    const actionItemLabel = (actionItem, notificationId, notification) => {
        return (
            <div>
                <a
                    onClick={() => onClickActionItem(actionItem.label, notificationId, notification)}
                    className={`${baseClassName}__action_item_label`}
                >
                    <img src={actionItem.icon} />
                    <span>{actionItem.label}</span>
                </a>
            </div>
        );
    };

    // menu for actions in each notification
    const menu = (notification) => {
        const isComponentAtHome = componentView === 'home';

        let filteredActions;
        const notificaitonActionHandle = isComponentAtHome ? notificationActions : notificationHeaderActions;
        filteredActions = notificaitonActionHandle.filter((action) => {
            if ((notification.isRead === null || !notification.isRead) && action.label === 'Mark As Read') {
                return true;
            }
            return action.label !== 'Mark As Read';
        });
        return (
            <Menu className={`${baseClassName}__action_item_dropdown`} onClick={(e) => handleActionSelection(e)}>
                {filteredActions.map((action) => {
                    const hasHyperLink =
                        notification?.message?.hyperLink ||
                        action.label === 'Dismiss' ||
                        action.label === 'Mark As Read';
                    if (hasHyperLink) {
                        return (
                            <Menu.Item key={action.key}>
                                {actionItemLabel(action, currentNotificationId, notification)}
                            </Menu.Item>
                        );
                    }
                })}
            </Menu>
        );
    };
    const noSubscriptionMessage = () => {
        return (
            <div>
                You have not licensed any categories yet. Go to your <ByzzerLink label={'Subscription'} onClick={() => navigate('/dashboard/my_subscription/subscription')} />  page to set up your account.
            </div>
        )
    }
    const noNotificationsMessage = ( view:string ) => {
        if( view === 'header' ){
            return (
                <div className={`${baseClassName}__header_none`}>
                <p> You're all caught up!</p>
                <div className={`${baseClassName}__header_description`}>Check <ByzzerLink label={'My Alerts'} onClick={() => navigate('/dashboard/my_alerts')} /> for your weekly data updates, or go to <ByzzerLink label={'My Reports'} onClick={() => navigate('/dashboard/my_reports')} /> to run a new report.</div>
                </div>
               
            )
        }
        return (
            <div>
                Nothing new here! Check <ByzzerLink label={'My Alerts'} onClick={() => navigate('/dashboard/my_alerts')} /> for your weekly data
                updates, or go to <ByzzerLink label={'My Reports'} onClick={() => navigate('/dashboard/my_reports')} /> to run a new report.
            </div>            
        )
               
    }

    return (
        <div className={classnames(baseClassName, className)}>
            {loading && (
                <div className="notification_spinner">
                    <Spinner />
                </div>
            )}
            {componentView !== 'header' && (((category?.length == 0 || category === undefined || category === null) && notificationItems?.length > 0) || 
                ((category?.length == 0 || category === undefined || category === null) && notificationItems?.length == 0)) && !dashboardLoading && noSubscriptionMessage()}
            {(category?.length > 0 || componentView === 'header') && notificationItems.length == 0 && !dashboardLoading && noNotificationsMessage( componentView )}
            {notificationItems.length > 0 && (
                <div className={`${baseClassName}__items`}>
                    {notificationItems.map((item, index) => (
                        <div key={index} id="antd" className={`${baseClassName}__item`}>
                            {componentView === 'header' && (item?.isRead === null || !item?.isRead) && <span className={`${baseClassName}__unread-badge`}></span>}
                                <img
                                    className={`${baseClassName}__icon`}
                                    src={notificationMap[item?.messageType.toUpperCase()]?.icon}
                                    alt={'warn'}
                                />
                                <p>
                                    <b>{item?.message?.header}</b> {item.message?.message}
                                </p>
                            <Dropdown
                                visible={visible && index == currentNotificationId}
                                onVisibleChange={(vis) => onToggleOfActionIcon(index, vis)}
                                key={index}
                                overlay={menu(item)}
                                trigger={['click']}
                            >
                                <img
                                    key={index}
                                    className={`${baseClassName}__dots`}
                                    src={visible && index == currentNotificationId ? dotsDisabled : dotsEnabled}
                                    alt={'warn'}
                                />
                            </Dropdown>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
});
export default Notification;