import './DodRunConfigWizard.scss';
import {ReactNode, useEffect, useMemo, useRef} from 'react';
import useState from 'react-usestateref';
import classnames from 'classnames';
import {TabWizard, TabWizardRef} from '@byzzer/ui-components';
import {MAX_DATA_POINTS, useDodWizard,} from '@/components/DodConfigEditor/DodRunConfigWizard/DodWizardContext';
import {
    DodFactsStep,
    DodLayoutStep,
    DodMarketFiltersStep,
    DodProductFiltersStep,
    DodTimePeriodFiltersStep,
} from '@/components/DodConfigEditor/DodRunConfigWizard/steps';
import {DodColDef, DodFactSet, DodFilters, DodRowDef, DodRunConfig, DodSchedule} from '@/types/DodRun';
import {DodScheduleStep} from './steps/DodScheduleStep';
import {alert} from '@/components/form';
import {
    openApplyDodLayoutModal,
    openCreateDodRunModal,
    openCreateDodScheduleModal,
    openModifyDodScheduleModal
} from '@/components/DodConfigEditor/common/DodRunNowModal/DodRunNowModal';
import {uniq} from "lodash";
import {toAxisDef} from "@/components/DodConfigEditor/toAxisDef";
import { DodRunConfigWizardMode } from '@/components/DodConfigEditor/types';
import {useEventDataWithUserInfo, useTrackEvent} from "@/analytics/AnalyticsContext";
import { showErrorModal } from '@/utils';
import { compareObjects, DodRunMethod, filterHasValues } from '../common/utils';

const baseClassName = 'dod-run-config-wizard';

export type DodRunConfigWizardProps = {
    name?: string;
    extractId?: number;
    className?: string;
    children?: ReactNode;
    initialRunConfig?: DodRunConfig;
    mode?: DodRunConfigWizardMode;
    onComplete?(redirectToSchedule?: boolean): void;
    onCancel?(): void;
};

export function DodRunConfigWizard({
                                       className,
                                       name,
                                       onComplete,
                                       initialRunConfig,
                                       mode = 'create',
                                       extractId,
                                       ...props
                                   }: DodRunConfigWizardProps) {
    const PRODUCT_TAB_INDEX: number = 0;
    const MARKET_TAB_INDEX: number = 1;
    const MAX_TABS: number = 5;
    const layoutOnly = mode === 'layout';
    const schedulerOnly = mode === 'editSchedule';
    const {setRunConfig, runConfig, rowColConfigs, dataPointCount, seriesNames, individualRunSeriesNames, presets, allExceptSelectionFields} = useDodWizard();
    const trackEvent = useTrackEvent();
    const getEventData = useEventDataWithUserInfo();
    const [busy, setBusy] = useState<boolean>(false);
    const [schedulerEnabled, setSchedulerEnabled] = useState<boolean>(false);
    const [schedule, setSchedule] = useState<DodSchedule>({
        frequency: 'one_time',
        deliveryDay: 'tuesday',
        deliveryGroupType: 'me',
        deliverToUserIds: [],
    } as any);
    const [initialSchedule, setInitialSchedule] = useState<DodSchedule>({
        frequency: '',
        deliveryDay: '',
        deliveryGroupType: '',
        deliverToUserIds: [],
    } as any);

    const [validTabs, setValidTabs] = useState([false, false, false, false, false]);
    const wizardRef = useRef<TabWizardRef>(null);
    const runDisabled = useMemo(() => {
        return dataPointCount > MAX_DATA_POINTS || Number(dataPointCount) === 0;
    }, [dataPointCount])

    useEffect(() => {
        switch (mode) {
            case 'edit':
            case 'editScheduleSeries': {
                wizardRef.current!.enableStep('layout');
                wizardRef.current!.enableStep('markets');
                wizardRef.current!.enableStep('timePeriods');
                wizardRef.current!.enableStep('products');
                wizardRef.current!.enableStep('facts');
                break;
            }
            case 'layout':
            case 'editSchedule': {
                break;
            }
            default:
                wizardRef.current!.disableStep('layout');
                wizardRef.current!.disableStep('markets');
                wizardRef.current!.disableStep('timePeriods');
                wizardRef.current!.disableStep('facts');
                wizardRef.current!.enableStep('products');
        }
    }, [mode]);

    useEffect(() => {
        if(initialRunConfig) {
            if(initialRunConfig.schedule) {
              setSchedule(initialRunConfig.schedule);
              setInitialSchedule(initialRunConfig.schedule);
            }

            switch (mode) {
                case 'edit':
                case 'layout':
                    wizardRef.current!.activeStep = 'layout';
                    break;
                case 'editSchedule':
                    wizardRef.current!.activeStep = 'scheduler';
                    break;
                default:
                    wizardRef.current!.activeStep = 'products';
            }
        }
    }, [initialRunConfig])

    useEffect(() => {
        // TODO: find a better approach to handle intercom button click
        // windows.Intercom("onOpen") listen for intercom button click
        // but found nothing to remove listener
        const intercomChatElement = document.getElementsByClassName('intercom-chat')?.[0];

        function trackClickEvent() {
            try {
                trackEvent({
                    type: 'click',
                    name: 'chat_click',
                    data: getEventData({ page:"Data on Demand", step: wizardRef.current?.activeStep }),
                });
            } catch (error) {
                console.error('Error tracking chat click event', error);
            }
        }

        if (intercomChatElement) {
            intercomChatElement.addEventListener('click', trackClickEvent);
        }

        return () => {
            if (intercomChatElement) {
                intercomChatElement.removeEventListener('click', trackClickEvent);
            }
        };
    }, []);

    function handleStepChange(e) {

        setRunConfig(config => ({
            ...config,
            [e.name]: e.value
        }));

        //There is currently logic to remove all markets if a product is deleted.  Deleting markets happens directly
        //on the runConfig and not on the markets tab so therefore a validityChange event does not happen on the markets
        //tab. Reset the validity of the markets tab so the logic below will enable/disable appropriately.

        if (wizardRef.current!.activeStepIndex! === PRODUCT_TAB_INDEX && (e.value.markets?.values?.length === 0)) {
            validTabs[MARKET_TAB_INDEX] = false;
            setValidTabs(validTabs);
            for (let i = MARKET_TAB_INDEX + 1; i < MAX_TABS; i++) {
                wizardRef.current!.disableStep(i);
            }
        }
    }

    function getFiltersWithExcludeValues(filters: DodFilters): DodFilters {
        const allExceptSelectionFieldsSet = new Set(allExceptSelectionFields);
        return {
            ...filters,
            ...Object.fromEntries(
            Object.entries(filters).map(([key, value]) => {
                if (allExceptSelectionFieldsSet.has(key)) {
                    return [key, { ...value, isExcluded: true }];
                }
                if (key === 'characteristics') {
                    const characteristics = value;
                    const characteristicsWithExcludeValues = Object.fromEntries(
                        Object.entries(characteristics).map(([key, value]) => {
                            if (allExceptSelectionFieldsSet.has(key)) {
                                return [key, { ...value, isExcluded: true }];
                            }
                            return [key, value];
                        })
                    );
                    return [key, characteristicsWithExcludeValues];
                }
                if (key === 'customCharacteristics') {
                    const customCharacteristics = value;
                    const customCharacteristicsWithExcludeValues = Object.fromEntries(
                        Object.entries(customCharacteristics).map(([key, value]) => {
                            // @ts-ignore
                            if (allExceptSelectionFieldsSet.has(parseInt(key)) || allExceptSelectionFieldsSet.has(key)) {
                                return [key, { ...value, isExcluded: true }];
                            }
                            return [key, value];
                        })
                    );
                    return [key, customCharacteristicsWithExcludeValues];
                }
                if(key === 'ppgs') {
                    const ppgs = value;
                    const ppgsWithExcludeValues = Object.fromEntries(
                        Object.entries(ppgs).map(([key, value]) => {
                            // @ts-ignore
                            if (allExceptSelectionFieldsSet.has(parseInt(key)) || allExceptSelectionFieldsSet.has(key)) {
                                return [key, { ...value, isExcluded: true }];
                            }
                            return [key, value];
                        })
                    );
                    return [key, ppgsWithExcludeValues];
                }
                return [key, value];
            })
        )}
    }

    async function handleCreateExtract(): Promise<void> {
        try {

            const {includeSubTotals, includeCategoryTotals, quickLayoutCode, templateId, savedLayoutId, includeExtractDate, isFutureTemplate} = runConfig.layout
            const appliedPresets = {
                ...(presets?.product && presets?.product.id !== -1 && {product: presets?.product.id}),
                ...(presets?.market && {market: presets?.market.id}),
                ...(presets?.time_period && {time_period: presets?.time_period.id}),
                ...(presets?.fact && {fact: presets?.fact.id}),
            }

            // use the rows and columns from the context not the layout
            // @ts-ignore
            const rows: DodRowDef[] = rowColConfigs.filter(config => config.axis === 'row').map(toAxisDef);
            const columns: DodColDef[] = rowColConfigs.filter(config => config.axis === 'col').map(toAxisDef);
            const overallUiOrder = uniq(rowColConfigs.map(config => config.type));
            const filtersWithExcludeValues:DodFilters = getFiltersWithExcludeValues(runConfig.filters);

            let extract;
            if(mode === 'layout' && extractId){
                extract = await openApplyDodLayoutModal({
                    runConfig: {
                        ...runConfig,
                        filters: filtersWithExcludeValues,
                        layout: {
                            includeCategoryTotals,
                            includeSubTotals,
                            includeExtractDate,
                            quickLayoutCode,
                            templateId,
                            savedLayoutId,
                            overallUiOrder,
                            rows,
                            columns,
                            isFutureTemplate
                        }
                    },
                    extractId,
                    seriesNames,
                    individualRunSeriesNames
                })
            } else {
                const isModifiedRun = extractId ? compareObjects(initialRunConfig, runConfig) : [];
                extract = await openCreateDodRunModal({
                    runConfig: {
                        ...runConfig,
                        filters: filtersWithExcludeValues,
                        layout: {
                            includeCategoryTotals,
                            includeSubTotals,
                            includeExtractDate,
                            quickLayoutCode,
                            templateId,
                            savedLayoutId,
                            overallUiOrder,
                            rows,
                            columns,
                            isFutureTemplate
                        },
                    },
                    ...(Object.keys(appliedPresets).length > 0 && {appliedPresets: appliedPresets}),
                    seriesNames,
                    individualRunSeriesNames,
                    runMethod: isModifiedRun.length > 0 ? DodRunMethod['modifiedRun'] : DodRunMethod[mode],
                    extractId
                });
            }
            if (extract) {
                onComplete?.();
            }
        } catch (err) {
            console.error('error in dod run config wizard', err)
            alert({
                type: 'error',
                title: 'Run Failed',
                content: <>
                    <p>There was an unexpected error during this run creation.</p>
                    <p>Please contact the support team for additional assistance.</p>
                </>
            })
        } finally {
            setBusy(false);
        }
    }

    function handleSchedule(): void {
        setSchedulerEnabled(true);
        wizardRef.current!.activeStep = 'scheduler';
    }

    async function handleCreateSchedule(): Promise<void> {
        try {
            const combinedSeriesNames = new Set([...seriesNames.individual, ...seriesNames.scheduled]);

            const {includeSubTotals, includeCategoryTotals, quickLayoutCode, templateId, savedLayoutId, includeExtractDate, isFutureTemplate} = runConfig.layout
            const appliedPresets = {
                ...(presets?.product && {product: presets?.product.id}),
                ...(presets?.market && {market: presets?.market.id}),
                ...(presets?.time_period && {time_period: presets?.time_period.id}),
                ...(presets?.fact && {fact: presets?.fact.id}),
            }

            // use the rows and columns from the context not the layout
            // @ts-ignore
            const rows: DodRowDef[] = rowColConfigs.filter(config => config.axis === 'row').map(toAxisDef);
            const columns: DodColDef[] = rowColConfigs.filter(config => config.axis === 'col').map(toAxisDef);
            const overallUiOrder = uniq(rowColConfigs.map(config => config.type));
            const filtersWithExcludeValues:DodFilters = getFiltersWithExcludeValues(runConfig.filters);

            const extract = await openCreateDodScheduleModal({
                schedule,
                runConfig: {
                    ...runConfig,
                    filters: filtersWithExcludeValues,
                    layout: {
                        includeCategoryTotals,
                        includeSubTotals,
                        includeExtractDate,
                        quickLayoutCode,
                        templateId,
                        savedLayoutId,
                        overallUiOrder,
                        rows,
                        columns,
                        isFutureTemplate
                    }
                },
                seriesNames: combinedSeriesNames,
                ...(Object.keys(appliedPresets).length > 0 && {appliedPresets: appliedPresets}),
                extractId,
            });
            if (extract) {
                onComplete?.(true);
            }
        } catch (err) {
            console.error('error in dod run config wizard', err)
            alert({
                type: 'error',
                title: 'Run Failed',
                content: <>
                    <p>There was an unexpected error during this run creation.</p>
                    <p>Please contact the support team for additional assistance.</p>
                </>
            })
        } finally {
            setBusy(false);
        }
    }

    async function handleModifySchedule(): Promise<void> {
        try {
            const { includeSubTotals, includeCategoryTotals, quickLayoutCode, templateId, savedLayoutId, includeExtractDate } = runConfig.layout;
            const appliedPresets = {
                ...(presets?.product && {product: presets?.product.id}),
                ...(presets?.market && {market: presets?.market.id}),
                ...(presets?.time_period && {time_period: presets?.time_period.id}),
                ...(presets?.fact && {fact: presets?.fact.id}),
            }
            // use the rows and columns from the context not the layout
            // @ts-ignore
            const rows: DodRowDef[] = rowColConfigs.filter((config) => config.axis === 'row').map(toAxisDef);
            const columns: DodColDef[] = rowColConfigs.filter((config) => config.axis === 'col').map(toAxisDef);
            const overallUiOrder = uniq(rowColConfigs.map((config) => config.type));
            const filtersWithExcludeValues:DodFilters = getFiltersWithExcludeValues(runConfig.filters);

            const extract = await openModifyDodScheduleModal({
                extractId: extractId!,
                runConfig: {
                    ...runConfig,
                    filters: filtersWithExcludeValues,
                    name: runConfig?.seriesName ? runConfig?.seriesName : runConfig?.name,
                    layout: {
                        includeCategoryTotals,
                        includeSubTotals,
                        includeExtractDate,
                        quickLayoutCode,
                        templateId,
                        savedLayoutId,
                        overallUiOrder,
                        rows,
                        columns,
                    },
                },
                schedule:{
                  ...schedule,
                  modificationType: mode === 'editSchedule' ? 'modify-series-schedule' : 'modify-series-selections',
                },
                ...(Object.keys(appliedPresets).length > 0 && {appliedPresets: appliedPresets}),
            });
            if (extract) {
                onComplete?.(true);
            }
        } catch (error) {
            console.error('Error updating schedule extract: ', error);
            showErrorModal(error);
        }
    }

    function handleScheduleChange(e: ByzzerChangeEvent<DodSchedule>): void {
        setSchedule(e.value);
    }

    function resetFactSelections(filterType: string): void {
        // when upc is selected in products tab, upc and brand level facts get enabled in facts tab, hence reseting both

        if (filterType === 'upcs') {
            // checking if upc required facts were selected and if they are selected, then remove them from selection.
            let updatedFactSet: DodFactSet[] = runConfig.facts.filter((fact) => {
                return !fact.isUPCrequired;
            });

            if (!filterHasValues(runConfig.filters.brands)) {
                updatedFactSet = updatedFactSet.map((fact) => {
                    return {
                        ...fact,
                        brand: false,
                        brandYearAgo: false,
                    };
                });
            }
            setRunConfig((runConfig) => {
                return {
                    ...runConfig,
                    facts: updatedFactSet,
                };
            });
        }

        // when brand is selected in products tab, only brand level facts get enabled in facts tab, hence reset only brand level facts below
        if (filterType === 'brands' && !filterHasValues(runConfig?.filters?.upcs)) {
            // checking if brand required facts were selected and if they are selected, then remove them from selection.
            setRunConfig((runConfig) => {
                return {
                    ...runConfig,
                    facts: runConfig.facts.map((fact) => {
                        return {
                            ...fact,
                            brand: false,
                            brandYearAgo: false,
                        };
                    }),
                };
            });
        }
    }

    function handleValidityChange(e) {
        //Enable/disable tabs based on their past validity values.
        //Note that this logic assumes an order of products->markets->all other tabs.

        validTabs[wizardRef.current!.activeStepIndex!] = e.isValid;

        //There is currently logic to remove all markets if a product is deleted.  Deleting markets happens directly
        //on the runConfig and not on the markets tab so therefore a validityChange event does not happen on the markets
        //tab. Reset the validity of the markets tab so the logic below will enable/disable appropriately.

        if (wizardRef.current!.activeStepIndex! === PRODUCT_TAB_INDEX && runConfig.filters.markets.values.length === 0) {
            validTabs[MARKET_TAB_INDEX] = false;
            setTimeout(() => {
                for (let i = MARKET_TAB_INDEX + 1; i < MAX_TABS; i++) {
                    wizardRef.current!.disableStep(i);
                }
            }, 500);
        }
        setValidTabs(validTabs);

        //If a tab becomes disabled because of invalidity, all subsequent tabs should be disabled.
        if (!e.isValid) {
          setTimeout(() => {
              for (let i = wizardRef.current!.activeStepIndex! + 1; i < MAX_TABS; i++) {
                  wizardRef.current!.disableStep(i);
              }
          }, 500);
        } else {
            //If this is the markets tab always enable it since this code block will never be reached if the first tab is not valid.
            wizardRef.current!.enableStep(MARKET_TAB_INDEX);
            for (let i = MARKET_TAB_INDEX; i < MAX_TABS; i++) {
                if (validTabs[i]) {
                    wizardRef.current!.enableStep(i + 1);
                } else {
                    break; //There is an assumption here that all the remaining tabs are still in a disabled state from previous runs through this logic.
                }
            }
        }
    }

    return (
        <div className={classnames(baseClassName, className)} {...props}>
            <TabWizard
                className={classnames(baseClassName, className)}
                disableInactiveStepRendering={true}
                ref={wizardRef}
            >
                <DodProductFiltersStep
                    title={'Select Products'}
                    name={'filters'}
                    value={runConfig.filters}
                    exclude={layoutOnly || schedulerOnly}
                    onChange={handleStepChange}
                    resetFactSelections={resetFactSelections}
                    onValidityChange={handleValidityChange}
                    enabled
                />
                <DodMarketFiltersStep
                    title={'Select Markets'}
                    name={'filters'}
                    value={runConfig.filters}
                    exclude={layoutOnly || schedulerOnly}
                    onChange={handleStepChange}
                    onValidityChange={handleValidityChange}
                    enabled={true}
                />
                <DodTimePeriodFiltersStep
                    title={'Select Time Periods'}
                    name={'filters'}
                    value={runConfig.filters}
                    exclude={layoutOnly || schedulerOnly}
                    onChange={handleStepChange}
                    onValidityChange={handleValidityChange}
                />
                <DodFactsStep
                    title={'Select Facts'}
                    name={'facts'}
                    value={runConfig.facts}
                    exclude={layoutOnly || schedulerOnly}
                    onChange={handleStepChange}
                    onValidityChange={handleValidityChange}
                    filters={runConfig.filters}
                />
                <DodLayoutStep
                    title={'Configure Layout'}
                    name={'layout'}
                    value={runConfig}
                    onCreate={handleCreateExtract}
                    onSchedule={handleSchedule}
                    onModifySchedule={handleModifySchedule}
                    busy={busy}
                    submitDisabled={runDisabled}
                    hideSchedule={layoutOnly}
                    isEditScheduleMode={mode === 'editScheduleSeries' || mode === 'editSchedule'}
                    exclude={schedulerOnly}
                />
                <DodScheduleStep
                    value={schedule}
                    onChange={handleScheduleChange}
                    onModifySchedule={handleModifySchedule}
                    enable={true}
                    exclude={!schedulerEnabled && !schedulerOnly}
                    isEditScheduleMode={mode === 'editScheduleSeries' || mode === 'editSchedule'}
                    onCreate={handleCreateSchedule}
                    submitDisabled={runDisabled}
                    initialSchedule={initialSchedule}
                />
            </TabWizard>
        </div>
    );
}

export default DodRunConfigWizard;
