import React, { useEffect, useState } from 'react';
import { ByzzerCarousel } from '@/components/ByzzerCarousel';
import CaseStudy from './CaseStudy';
import { useSharedApi } from '@/hooks/useSharedApi';
import { CaseStudySlide } from '@/types/ApiTypes';

const baseClassName = `case-study`;

type ModifiedCaseStudySlides = {
    case: string;
    slides: CaseStudySlide[];
};

const CaseStudyWrapper: React.FC = () => {
    const { getCaseStudySlides } = useSharedApi();
    const [caseStudies, setCaseStudies] = useState<ModifiedCaseStudySlides[]>([]);

    useEffect(() => {
        fetchCaseStudies();
    }, []);

    const fetchCaseStudies = async () => {
        try {
            const response: CaseStudySlide[] = await getCaseStudySlides();
            const chunkedResponse = chunkArray(response, 3);
            setCaseStudies(chunkedResponse);
        } catch (error) {
            console.error('Error fetching case studies:', error);
        }
    };

    // Helper function to chunk the array of CaseStudySlide objects
    const chunkArray = (array: CaseStudySlide[], chunkSize: number): ModifiedCaseStudySlides[] => {
        const chunks: ModifiedCaseStudySlides[] = [];
        for (let index = 0; index < array.length; index += chunkSize) {
            chunks.push({
                case: `Case Study Chunk ${Math.floor(index / chunkSize) + 1}`,
                slides: array.slice(index, index + chunkSize),
            });
        }
        return chunks;
    };

    return (
        <div className={`${baseClassName}__container`}>
            <div className={`${baseClassName}__heading_container`}>
                <div className={`${baseClassName}__heading_container__heading`}>Byzzer Case Studies</div>
                <div className={`${baseClassName}__heading_container__sub-heading`}>
                    See how small and emerging brands like yours have leveraged the power of Byzzer to tackle key
                    business needs.
                </div>
            </div>

            <ByzzerCarousel
                className={`${baseClassName}__carousel-wrapper`}
                itemClass={`${baseClassName}__carousel-wrapper-item`}
            >
                {caseStudies.map(({ slides, case: caseName }) => (
                    <CaseStudy key={caseName} baseClassName={baseClassName} caseStudies={slides} />
                ))}
            </ByzzerCarousel>
        </div>
    );
};

export default CaseStudyWrapper;
