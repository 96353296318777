import './DodSchedule.scss';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import useStateRef from 'react-usestateref';
import { AgGridReact } from 'ag-grid-react';
import { formatInTimeZone } from 'date-fns-tz';
import { useNavigate } from 'react-router-dom';
import ModifyExtract from '@/views/Extract/ExtractHistory/ModifyExtract';
import DeleteIcon from '@images/icons/DeleteIcon.svg';
import EditIcon from '@images/icons/EditIcon.svg';
import { ByzzerTable } from '@/components/ByzzerTable';
import { ByzzerMask } from '@/components/ByzzerMask/ByzzerMask';
import { ByzzerMenu } from '@/components/ByzzerMenu';
import { confirm, openErrorModal, alert as byzzerAlert, ByzzerModal } from '@/components/form/ByzzerModal';
import { openModifySeriesName } from '@/components/DodConfigEditor/common/DodRunNowModal/DodRunNowModal';
import { DodRunConfigWizardMode } from '@/components/DodConfigEditor/types';
import { triggerToast } from '@/notifications/ByzzerToast';
import { useTenantApi } from '@/hooks';
import { useUser } from '@/contexts/UserContext';
import { FLOATING_DATE_PICKER_FILTER_PARAMS } from '@/constants/table.constants';
import { DodScheduleHistory } from '@/types/DodRun';
import { frameTimePeriod } from '@/services/extract.service';
import { getYearsFromSubscriptionData } from '@/utils/timeperiodUtil';
import { isValidArray } from '@/utils';
import { mergeCategories } from '@/utils/extractUtil';
import { getDodSeriesNames } from '@/api';
import { ByzColDef } from '@/types/TableTypes';

type DodScheduleColId = 'checkbox' | 'runName' | 'seriesName' | 'id' | 'extractDelivery' | 'runBy' | 'timePeriod' | 'categories' | 'menu';

function DodSchedule({handleSelectionChange}) {
    const baseClassName = 'dod-schedule';
    const gridRef = useRef<AgGridReact>(null);
    const [scheduledRuns, setScheduledRuns] = useState<DodScheduleHistory[]>([]);
    const { getScheduledExtracts, deleteExtractReport } = useTenantApi();
    const [rowData, setRowData] = useState<DodScheduleHistory>();
    const [showEditDataModal, setShowEditDataModal] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [editOption, setEditOption] = useState<DodRunConfigWizardMode | undefined>(undefined);
    const [_, setSeriesNames, seriesNamesRef] = useStateRef<{
        [key: string]: Set<string>
    }>({});
    const navigate = useNavigate();
    const defaultDateFormat = 'yyyy-MM-dd';
    const { features, user, categories: userCategories } = useUser();
    const userRole = user?.role ?? 'user';
    let yrs = getYearsFromSubscriptionData(features?.extendedDataYears);
    const menuRefs = useRef<any[]>([]);
    const abortControllerRef = useRef<AbortController | null>(null);

    const isUserMode = () => {
        return userRole !== 'viewer';
    };

    useEffect(() => {
        (async () => {
            try {
                await getScheduledRuns();
                handleSelectionChange([]);
            } catch (err) {
                if (err instanceof Error && err.name !== 'AbortError') {
                    console.error('Error fetching scheduled runs', err);
                }
            }
        })();

        return () => {
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }
        };
    }, []);

    const defaultDodScheduleColDef = useMemo(
        () => ({
            filter: true, // make every column use 'text' filter by default
            sortable: true,
            floatingFilter: true, // enable floating filters by default
            resizable: true, //set each col resizable
            // width: 200, // set every column width
            flex: 1,
            autoHeight: false,
            wrapText: false,
            suppressMenu: true,
            lockVisible: true,
            sizeColumnsToFit: true,
        }),
        []
    );

    const updateSelectedRows = () => {
        const selectedNodes = gridRef?.current?.api?.getSelectedNodes();
        const selectedData = selectedNodes?.map(node => node.data);
        handleSelectionChange(selectedData);
    };

    useEffect(() => {
        if (gridRef.current?.api) {
            gridRef.current?.api?.addEventListener('selectionChanged', updateSelectedRows);
        }
        return () => {
            if (gridRef.current?.api) {
                gridRef.current?.api.removeEventListener('selectionChanged', updateSelectedRows);
            }
        };
    }, [scheduledRuns]);
    const [dodScheduleColumnDefs] = useState<ByzColDef<DodScheduleHistory, DodScheduleColId>[]>([
        {
            colId: 'checkbox',
            headerCheckboxSelection: true,
            checkboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            sortable: false,
            filter: false,
            resizable: false,
            width: 50,
            maxWidth: 50,
            minWidth: 50,
        },
        {
            colId: 'runName',
            headerName: 'Run Name',
            width: 300,
            valueGetter: ({ data }) => data.reportName,
            tooltipValueGetter: ({ data }) => data.reportName,
            disableHide: true
        },
        {
            colId: 'seriesName',
            headerName: 'Series Name',
            width: 300,
            valueGetter: ({ data }) => data.extractInfo?.scheduledInfo?.seriesName,
            tooltipValueGetter: ({ data }) => data.extractInfo?.scheduledInfo?.seriesName,
        },
        ...(window.localStorage['show-schedule-dod-id'] === 'true' ? [{ colId: 'id' as const }] : []),
        {
            ...FLOATING_DATE_PICKER_FILTER_PARAMS,
            headerName: 'Scheduled Date',
            colId: 'extractDelivery',
            field: 'extractDelivery',
            tooltipValueGetter: ({ data }) => {
                return `${data.extractDelivery}`;
            },            
        },
        {
            colId: 'runBy',
            headerName: 'Run By',
            valueGetter: ({ data }) => {
                return `${data.user.firstName} ${data.user.lastName}`;
            },
            tooltipValueGetter: ({ data }) => {
                return `${data.user.firstName} ${data.user.lastName}`;
            },
        },
        {
            colId: 'timePeriod',
            headerName: 'Time Period',
            valueGetter: ({ data }) => {
                return frameTimePeriod(data.ExtractSelection, yrs);
            },
            tooltipValueGetter: ({ data }) => {
                return frameTimePeriod(data.ExtractSelection, yrs);
            },
        },
        {
            colId: 'categories',
            headerName: 'Category',
            // width: 300,
            valueGetter: ({ data }) => {
                const categoriesData = data?.ExtractSelection?.products?.categories;
                const categories = Array.isArray(categoriesData)
                    ? categoriesData.join(', ')
                    : Array.isArray(categoriesData?.selections)
                    ? categoriesData.selections.join(', ')
                    : typeof categoriesData?.selections === 'boolean' && categoriesData?.selections
                    ? 'ALL'
                    : '';

                const summedCategories = categoriesData?.summedSelections
                    // @ts-ignore: summedSelections is not typed
                    ?.filter(sc => sc?.display).map(sc => `${sc.display} (sum)`)
                    .join(', ');

                return [categories, summedCategories].filter(Boolean).join(', ');
            },
            tooltipValueGetter: ({ data }) => {
                const categoriesData = data?.ExtractSelection?.products?.categories;
                const categories = Array.isArray(categoriesData)
                    ? categoriesData.join(', ')
                    : Array.isArray(categoriesData?.selections)
                    ? categoriesData.selections.join(', ')
                    : typeof categoriesData?.selections === 'boolean' && categoriesData?.selections
                    ? 'ALL'
                    : '';

                const summedCategories = categoriesData?.summedSelections
                    // @ts-ignore: summedSelections is not typed
                    ?.filter(sc => sc?.display).map(sc => `${sc.display} (sum)`)
                    .join(', ');

                return [categories, summedCategories].filter(Boolean).join(', ');
            },
            filterParams: {
                textMatcher: ({ filterOption, filterText, data }) => {
                    const value = data?.categories?.join(', ')?.toLowerCase() || "";
                    if (filterText == null) {
                        return false;
                    }
                    switch (filterOption) {
                        case 'contains':
                            return value.indexOf(filterText?.toLowerCase() ?? '') >= 0;
                        case 'notContains':
                            return value.indexOf(filterText) < 0;
                        case 'equals':
                            return value === filterText;
                        case 'notEqual':
                            return value != filterText;
                        case 'startsWith':
                            return value.indexOf(filterText) === 0;
                        case 'endsWith':
                            const index = value.lastIndexOf(filterText);
                            return index >= 0 && index === (value.length - filterText.length);
                        default:
                            return false;
                    }
                }
            }
        },
        {
            headerName: 'Actions',
            colId: 'menu',
            suppressMenu: true,
            sortable: false,
            filter: false,
            floatingFilter: false,
            resizable: false,
            pinned: 'right',
            width: 150,
            cellRenderer: ({ data }) => {
                const { id, extractInfo } = data;
                const handleModify = () => {
                    setRowData(data);
                    setShowEditDataModal(true);
                };
                return (
                    <div className={`${baseClassName}__actions-cell`}>
                        {isUserMode() && (
                            <>
                                <span onClick={() => handleModify()} className={classNames(`${baseClassName}__edit-menu`)}>
                                    <img src={EditIcon} alt="Modify" style={{ cursor: 'pointer', marginRight: '10px' }} />
                                </span>
                                <span onClick={() => onDeleteRun(data.id)} className={classNames(`${baseClassName}__delete-menu`)}>
                                    <img src={DeleteIcon} alt="Delete" style={{ cursor: 'pointer' }} />
                                </span>
                            </>
                        )}
                        <div className={`${baseClassName}__actions-cell__dropdown`} >
                            <div
                                key={data.id}
                                className={classNames(`${baseClassName}__menu-trigger`)}
                                ref={thisElement => menuRefs.current[String(id)] = {current: thisElement}}
                            />
                            <ByzzerMenu
                                className={`${baseClassName}__menu`}
                                reference={menuRefs.current[String(id)]}
                                offset={[-90, -5]}
                                triggerTarget={menuRefs.current[String(id)]?.current}
                                highlightItemOnHover={true}
                                items={[
                                    {
                                        onClick: async function() {
                                            if(!extractInfo.scheduledInfo.seriesName || !isUserMode()) return;

                                            if(extractInfo.scheduledInfo.seriesName){
                                                try {
                                                    if(data.user.id !== user?.id){
                                                        byzzerAlert({
                                                            content: <>
                                                                <p>You cannot edit this series name because you did not create it.</p>
                                                            </>
                                                        })
                                                        return;
                                                    }
                                                    const updatedReportName = await openModifySeriesName(id, extractInfo.scheduledInfo.seriesName, true, seriesNamesRef?.current?.scheduled);
                                                    if(updatedReportName){
                                                        await getScheduledRuns();
                                                        triggerToast({
                                                            content: 'series name updated successfully',
                                                        });
                                                    }
                                                } catch(error) {
                                                    byzzerAlert({
                                                        type: 'error',
                                                        title: 'Series Name Update Failed',
                                                        content: <>
                                                            <p>There was an unexpected error during this series name update.</p>
                                                            <p>Please contact the support team for additional assistance.</p>
                                                        </>
                                                    })
                                                }
                                            }
                                        },
                                        content: 'Rename Series',
                                        disabled: !extractInfo.scheduledInfo.seriesName || !isUserMode()
                                    }
                                ]}
                            />
                        </div>
                    </div>
                );
            },
            disableHide: true
        },
    ]);
    const fetchSeriesNames = useCallback(async () => {
        try {
            const response = await getDodSeriesNames();
            const individual = isValidArray(response.individualSeriesNames)
                ? new Set(response.individualSeriesNames.map((name) => name.trim().toLowerCase()))
                : new Set([]);
            const scheduled = isValidArray(response.scheduledSeriesNames)
                ? new Set(response.scheduledSeriesNames.map((name) => name.trim().toLowerCase()))
                : new Set([]);
            setSeriesNames({ individual, scheduled });
        } catch (error) {
            console.error('Error fetching DOD series names:', error);
        }
    }, [getDodSeriesNames]);

    useEffect(() => {
        fetchSeriesNames();
    }, []);
    async function getScheduledRuns() {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
        abortControllerRef.current = new AbortController();
        const signal = abortControllerRef.current.signal;

        try {
            setLoading(true);
            const resp = await getScheduledExtracts(signal);
            const sortedRuns = resp.getExtractData.sort((a, b) =>
                b.id > a.id ? 1 : -1 || b.extractDelivery.localeCompare(a.extractDelivery)
            );
            const scheduledDodRuns = sortedRuns.map((item) => {
                const summedCategories = item?.ExtractSelection?.products?.categories?.summedSelections?.map((sc) =>
                    // @ts-ignore: summedSelections is not typed
                    mergeCategories([], sc?.selections, userCategories)
                );
                const categories = mergeCategories(
                    item?.ExtractSelection?.products?.categories?.selections ??
                        item?.ExtractSelection?.products?.categories,
                    summedCategories,
                    userCategories
                );
                return {
                    ...item,
                    extractDelivery: item.extractDelivery
                        ? formatInTimeZone(new Date(item.extractDelivery), 'GMT', defaultDateFormat)
                        : 'NA',
                    categories,
                };
            });
            setScheduledRuns(scheduledDodRuns);
        } catch(err:unknown) {
            if (err instanceof Error && err.name !== 'AbortError') {
                console.error('Error fetching scheduled runs: ', err);
            }
        } finally {
            setLoading(false);
        }
    }

    async function onDeleteRun(id) {
        try {
            if (
                !(await confirm({
                    title: 'Delete Scheduled Run',
                    content: <div className="byzzer-allocation-warning">Do you want to delete the selected item ?</div>,
                    yesLabel: 'Yes',
                    noLabel: 'No',
                }))
            ) {
                return;
            }
            setLoading(true);
            let resp = await deleteExtractReport(id);
            let alertContent = resp === 'deleted' ? 'Dod Schedule deleted successfully!' : 'Something went wrong';
            setLoading(false);
            byzzerAlert({
                // @ts-ignore
                content: <div className="story_modal_alertText">{alertContent}</div>,
            });
            if (resp === 'deleted') {
                getScheduledRuns();
            }
        } catch (err: any) {
            openErrorModal({
                title: `Something Unexpected Happened`,
                content: (
                    <>
                        <p>Fear not our engineering team is on the job.</p>
                    </>
                ),
                errorId: err.id
            });
            setLoading(false);
        }
    }

    async function closeEditModal() {
        setShowEditDataModal(false);
        setEditOption(undefined);
    }

    const nextClickedHandler = (radioValue: DodRunConfigWizardMode) => {
        navigate(`/dashboard/extract_editor/${rowData?.id}?mode=${radioValue}`);
    };

    const getBody = () => {
        return (
            <div className="btn-run-extract">
                <ModifyExtract
                    onNextClicked={nextClickedHandler}
                    disableNextButton={!editOption}
                    editOption={editOption}
                    saveEditOption={(selectedValue: DodRunConfigWizardMode) => setEditOption(selectedValue)}
                    seriesName={rowData?.extractInfo?.scheduledInfo?.seriesName}
                    scheduledBy={rowData?.user}
                />
            </div>
        );
    };

    return (
        <>
            <ByzzerMask show={loading} loading={loading}>
                Loading your Data On Demand runs
            </ByzzerMask>
            {showEditDataModal && (
                <ByzzerModal
                    show={showEditDataModal}
                    onClose={() => closeEditModal()}
                    size="small"
                    type="info"
                    className="extracts-edit-container"
                    heading="What do you want to modify?"
                >
                    {getBody()}
                </ByzzerModal>
            )}
            <ByzzerTable
                ref={gridRef} // Ref for accessing Grid's API
                rowData={scheduledRuns} // Row Data for Rows
                columnDefs={dodScheduleColumnDefs} // Column Defs for Columns
                defaultColDef={defaultDodScheduleColDef} // Column Defs for Columns
                readOnlyEdit={false}
                singleClickEdit={false}
                rowSelection='multiple'
                suppressRowClickSelection={true}
                tableArea={'dodSchedule'}
                enableColumnHideShow={true}
                enableSaveLayout={true}
                enableSaveSort={true}
            />
        </>
    );
}

export default DodSchedule;
