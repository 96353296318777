import React, { forwardRef, ReactNode, useEffect, useState, useCallback } from 'react';
import { ByzzerChangeEventHandler, ByzzerSearch, WithValue } from '@byzzer/ui-components';
import {useTenantApi} from '@/hooks/useTenantApi';
import { useApp } from '@/contexts/UserContext';

export type OmniBrandLowSearchProps = {
    value?: string[];
    className?: string;
    name?: string;
    label?: ReactNode;
    onChange?: ByzzerChangeEventHandler<string[]>;
    placeholder?: string;
    maxBrandsLow?: number; //decides how many brands we can add in dropdown
    disabled?: boolean;
    omniCategories?: string[];
} & OnlyRenderableIf;

export const OmniBrandLowSearch = forwardRef<WithValue<string[]>, OmniBrandLowSearchProps>(
    (
        {
            className,
            label,
            name,
            onChange,
            value,
            placeholder = 'Search For Omni Brands Low',
            maxBrandsLow,
            disabled,
            onlyRenderIf = true,
            omniCategories,
            ...props
        },
        ref
    ) => {
        if (onlyRenderIf === false) return <></>;
        const { findOmniBrandLowForCategories } = useTenantApi();
        const { omniCategories: omniCategoriesFromSubscription } = useApp();
        const omniCategoriesList = omniCategories ?? omniCategoriesFromSubscription;
        const [internalValue, setInternalValue] = useState<string[]>();

        useEffect(() => {
            setInternalValue(value);
        }, [value]);

        const findBrandsLowForOmni = useCallback(
            async (searchText: string): Promise<{ brandsfamily: string[] }> => {
                const { brandLow } = await findOmniBrandLowForCategories({
                    categories: omniCategoriesList, 
                    searchTerm: searchText
                }); // todo - recommend in Alby, use context categories vs select categories flag.
                return brandLow;
            },
            [omniCategoriesList]
        );

        const handleChange = (e: any) => {
            const newBrandLowPayload = {
                value: e.value.map((brandLow) => brandLow.value),
                name,
                data: e.data,
            };
            if (onChange) {
                onChange?.(newBrandLowPayload);
            } else {
                setInternalValue(e.data);
            }
        };

        return (
            <ByzzerSearch
                ref={ref}
                name={name}
                className={className}
                search={findBrandsLowForOmni}
                onChange={handleChange}
                value={internalValue}
                label={label as any}
                placeholder={placeholder}
                maxSelections={maxBrandsLow}
                disabled={disabled}
            />
        );
    }
);
export default OmniBrandLowSearch;

OmniBrandLowSearch.displayName = 'OmniBrandLowSearch';
