import { ByzzerInput, ByzzerSelect } from "@/components/form";
import React, { useState } from "react";
import { TipIcon } from "@/components/icons";
import {useTenantApi} from "@/hooks/useTenantApi";
import { validate as isValidEmail } from "email-validator";
import { openErrorModal, confirm, alert } from "@/components/form/ByzzerModal";
import { create as createModal } from "react-modal-promise";
import classNames from "classnames";
import ByzzerModal2 from "@/components/modals/ByzzerModal2";
import { openTeamRoleInfo } from "@/components/TeamManager/TeamRoleInfo";
import { openTeamInfo } from "@/components/TeamManager/TeamInfo";
import { openDomainMismatchWarning } from "./DomainMismatchWarning";
import { TrackClick } from '@/analytics';
import { useUser } from "@/contexts/UserContext";
import { UserInvite } from "@/types/InvitationTypes";
import { ByzzerButton } from "@byzzer/ui-components";
import { emailDomainFoundInDomainList } from "@/utils";

const teamRoleOptions = [
    { value: 'admin', display: 'Admin' },
    { value: 'user', display: 'User' },
    { value: 'viewer', display: 'Viewer' }
];

const baseClassName = 'subscription-users';

const onlyViewerRole = [{ value: 'viewer', display: 'Viewer' }]

const DOMAIN_IN_USE_MESSAGE_TITLE = 'Domain already associated with another account';
const DOMAIN_IN_USE_MESSAGE_CONTENT = 'This user has a domain name currently used by another Byzzer account. Please reach out to the support team through the chat to get your invitation approved.';
const DIFFERENT_DOMAIN_TITLE = 'Different Domain';
const DIFFERENT_DOMAIN_MESSAGE = ' This user has a domain name different than the domain name approved for your account. Please reach out to the support team through the chat to get your invitation approved.';

export function InvitationEditor({ teamOptions, availableUserCredits, onResolve, members, ...props }) {
    const { inviteUser, getCompanyMultiTenancyForUserEmail } = useTenantApi();
    const { nsCompany: userNsCompany, isMultiTenant: userHasMultiTenancy, user, company, features } = useUser();
    const [busy, setBusy] = useState(false)
    const [invitee, setInvitee] = useState<UserInvite>({
        firstName: '',
        lastName: '',
        email: '',
        role: '',
        teamId: undefined
    });

    const duplicateInfoMessage = (
        <>
            <p>
                The user you're trying to invite already has access through another account. Don't worry - that doesn't
                mean they're working for someone else! You may have both signed up separately, and created two accounts
                for your company.
            </p>
            <p>For help inviting this user, reach out to support through the Chat.</p>
        </>
    );
    const minimumInputLength = 5;

    async function onTeamRoleInfo() {
        openTeamRoleInfo()
    }

    async function onTeamInfo() {
        const result = await openTeamInfo()
        if (result.function === "redirect") {
            onCloseClick();
        }
    }

    const CannotInviteUserMessage = () => {
        let message: string = '';

        const {email} = invitee;

        if (email.length <= minimumInputLength) {
        } else if (!isValidEmail(email)) {
            // not valid email
            message = 'Email address is not valid.';
        } else if (members?.map((mbr) => mbr?.email)?.includes(email)) {
            // member or invite exists already
            message = 'This email is associated with an existing user or invited user.';
        }
        return <span className={classNames(`${baseClassName}__cannot-add-user`)}>{message}</span>;
    }

    const inviteeEmailIsWithinCurrentCompanyDomains: boolean = emailDomainFoundInDomainList(invitee.email, userNsCompany?.domains);
    const userLoggedIntoPrimaryCompany: boolean = user?.primaryCompanyId === company?.id;
    const userLoggedIntoSecondaryCompany: boolean = Boolean(user?.primaryCompanyId) && Boolean(company?.id) && user?.primaryCompanyId !== company?.id;
    const sendBtnLabel = busy ? 'Loading...' : userHasMultiTenancy && userLoggedIntoPrimaryCompany && !inviteeEmailIsWithinCurrentCompanyDomains ? 'Request Invite' : 'Send Invite';

    const sendUserInvitation = async () => {

        if (!canSubmit()) return;

        setBusy(true);

        if (inviteeEmailIsWithinCurrentCompanyDomains) {
            // email is within user's domain. can invite
            try {
                const invitationResult = await inviteUser(invitee);
                onResolve(invitationResult);
            } catch (err: any) {
                await openErrorModal({
                    title: 'Duplicate Email Address',
                    content: <p>Please reach out to the Byzzer Support Team for assistance.</p>
                });
            } finally {
                setBusy(false);
            }
        } else {
            try {
                const { 
                    userFound: inviteeUserFound, 
                    multiTenantEnabled: inviteeMultiTenantEnabled, 
                    domainInUse,
                    domainInCurrentCompanyDomains: inviteeDomainInuserCurrentCompanyDomains,
                    domainInPrimaryCompanyDomains: inviteeDomainInUserPrimaryCompanyDomains,
                } = await getCompanyMultiTenancyForUserEmail(invitee?.email);

                const showConfirmForNormalUser = domainInUse && !userHasMultiTenancy;
                const showConfirmForMTPrimaryCompanyUser = sendBtnLabel === 'Request Invite';
                const showConfirmForMTSecondaryCompanyUser = domainInUse && userHasMultiTenancy && userLoggedIntoSecondaryCompany;
                const showDomainConfirm = showConfirmForNormalUser || showConfirmForMTPrimaryCompanyUser || showConfirmForMTSecondaryCompanyUser;

                const domainInUseMessage = {
                    title: DOMAIN_IN_USE_MESSAGE_TITLE,
                    content: DOMAIN_IN_USE_MESSAGE_CONTENT
                };

                const differentDomainMessage = {
                    title: DIFFERENT_DOMAIN_TITLE,
                    content: DIFFERENT_DOMAIN_MESSAGE
                };

                if (!inviteeUserFound) {
                    let invitees = [invitee];
                    let domainMismatchInvitees = [...invitees];

                    if (showDomainConfirm) {
                        if (await confirm({
                            ...(showConfirmForMTPrimaryCompanyUser ? differentDomainMessage : domainInUseMessage),
                            yesLabel: 'Ok',
                            noLabel: 'Cancel',
                        })) {
                            const invitationResult = await inviteUser(invitee);
                            onResolve(invitationResult)
                            setBusy(false)
                        }
                    } else if (features?.allowDifferentDomains) {
                        const invitationResult = await inviteUser(invitee);
                        onResolve(invitationResult)
                        setBusy(false)
                    } else {
                        // @ts-ignore
                        const domainMismatchReasonSelected = await openDomainMismatchWarning({ invitees, onResolve, domainMismatchInvitees })
                        if (domainMismatchReasonSelected) {
                            const invitationResult = await inviteUser(invitee, domainMismatchReasonSelected.data.domainMismatchReasonCode);
                            onResolve(invitationResult)
                            setBusy(false)
                        }
                    }
                } else if (inviteeMultiTenantEnabled) {
                    if (userHasMultiTenancy && userLoggedIntoPrimaryCompany && !inviteeDomainInUserPrimaryCompanyDomains) { // BYZ-12635
                        return await alert({
                            title: `Attention`,
                            content: `The following user cannot be invited to this company: ${invitee.email}`
                        })
                    }
                    // email exists part of another domain and IS multi-tenant
                    setBusy(false)
                    if (await confirm({
                        title: 'Attention: Third-Party Advisor Access',
                        content: 'Looks like the user you are trying to add is a Byzzer approved third party advisor. To authorize their access please use the "Add a Third party" Option',
                        yesLabel: 'Add A Third Party',
                        noLabel: 'Cancel',
                    })) {
                        const { role, email, teamId } = invitee;
                        onResolve({ 
                            type: 'consultant',
                            data: {
                                role,
                                email,
                                teamId,
                            }
                        })
                    };
                } else {
                    setBusy(false)
                    // email exists part of another domain and ISNT multi-tenant
                    await openErrorModal({
                        title: 'That user already exists',
                        content: duplicateInfoMessage
                    });
                    onResolve(false)
                }
            } catch (err: any) {
                console.error(err);
                await openErrorModal({
                    title: 'Uh Oh',
                    content: <p>Something unexpected happened while sending your invitation.</p>,
                    errorId: err.id
                });
            } finally {
                setBusy(false);
            }
        }
    }

     const handleSendClick = async () => {
            await sendUserInvitation();
    }

    function canSubmit() {
        const {firstName, lastName, email, teamId, role} = invitee;
        return Boolean(firstName?.trim()
            && lastName?.trim()
            && isValidEmail(email)
            && teamId
            && role?.toLowerCase()
            && !members?.map((mbr) => mbr?.email)?.includes(email)
        );
    }

    function updateUser({ target }) {
        setInvitee(current => ({
            ...current,
            [target.name]: target.value,
        }));
    }

    function setTeamRole(role) {
        setInvitee(current => ({
            ...current,
            role
        }));
    }

    function setTeamId(teamId) {
        setInvitee(current => ({
            ...current,
            teamId
        }));
    }

    function onCloseClick() {
        onResolve(false);
    }


    return (
        // @ts-ignore
        <ByzzerModal2 className={classNames(`${baseClassName}__member-editor`)}>
            <ByzzerModal2.Header className={classNames(`${baseClassName}__modal-title`)} onClose={onCloseClick}>
                Add Member
            </ByzzerModal2.Header>
            {/* @ts-ignore */}
            <ByzzerModal2.Content>
                {/* @ts-ignore */}
                <ByzzerInput
                    label={'First Name'}
                    className={classNames(`${baseClassName}__modal-add-item-input`)}
                    placeholder={"First Name"}
                    name="firstName"
                    value={invitee.firstName}
                    onChange={updateUser}
                    validate={false}
                    variant={'secondary'}
                />
                {/* @ts-ignore */}
                <ByzzerInput
                    label={'Last Name'}
                    className={classNames(`${baseClassName}__modal-add-item-input`)}
                    placeholder={"Last Name"}
                    name="lastName"
                    value={invitee.lastName}
                    onChange={updateUser}
                    validate={false} 
                    variant={'secondary'}    
                />
                {/* @ts-ignore */}
                <ByzzerInput           // @ts-ignore
                    label={<span>Email <CannotInviteUserMessage /></span>}
                    // label={<>Email {emailExistsAlready ? <span className={classNames(`${baseClassName}__warning-text`)}>  *User already invited</span> : ''}</>}
                    className={classNames(`${baseClassName}__modal-add-item-input`)}
                    name="email"
                    value={invitee.email}
                    onChange={updateUser}
                    type={'email'}
                    placeholder={'first.last@email.com'}
                    variant={'secondary'}
                />
                <div className="member-editor__form-group">
                    {/* @ts-ignore */}
                    <h2>Member Type <TipIcon onClick={onTeamRoleInfo}/></h2>
                    {/* @ts-ignore */}
                    <ByzzerSelect
                        className={classNames(`${baseClassName}__modal-add-item-input`)}
                        name={'role'}
                        value={invitee.role}
                        onChange={setTeamRole}
                        placeholder={'Member Type'}
                        options={availableUserCredits < 1 ? onlyViewerRole : teamRoleOptions} 
                        variant={'secondary'}
                    />
                </div>
                <div className="member-editor__form-group">
                    {/* @ts-ignore */}
                    <h2>Choose a Team <TipIcon onClick={onTeamInfo}></TipIcon></h2>
                    {/* @ts-ignore */}
                    <ByzzerSelect
                        className={classNames(`${baseClassName}__modal-add-item-input`)}
                        name={'team'}
                        value={invitee?.teamId}
                        onChange={setTeamId}
                        placeholder={'Choose a Team'}
                        options={teamOptions} 
                        variant={'secondary'}
                    />
                </div>
            </ByzzerModal2.Content>
            <ByzzerModal2.Footer className={classNames(`${baseClassName}__modal-footer`)}>
                <TrackClick name={'Sub Members Add Members Invite clicked'}>
                    <ByzzerButton className={classNames(`${baseClassName}__modal-footer-cancel`)} type="negative"
                        onClick={() => onResolve(false)} label="Cancel" disabled={busy}/>
                </TrackClick>
                <ByzzerButton label={sendBtnLabel} disabled={!canSubmit() || busy}
                    className={classNames(`${baseClassName}__modal-footer-invite`)}
                    onClick={handleSendClick}
                />
            </ByzzerModal2.Footer>
        </ByzzerModal2>
    );
}
// @ts-ignore
export const openInvitationEditor = createModal(InvitationEditor);