import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, IDateFilterParams, NestedFieldPaths } from 'ag-grid-community';
import FavoriteIcon from '@/components/icons/FavoriteIcon';
import { useTenantApi } from '@/hooks';
import { format } from 'date-fns';
import { alert as byzzerAlert, confirm, openErrorModal } from '@/components/form';
import { useEvents, useUser } from '@/contexts/UserContext';
import { alertMessages, eventTypes, storySkus } from '@/config/globalVars';
import { errorCodeDetails, useBetterNavigate, useSetState } from '@/utils';
import { initializeStoredValues, storyAppendMore } from '@/utils/storiesUtil';
import { Tooltip } from 'antd';
import className from 'classnames';
import './StoriesHistoryPage.scss';
import '@/pages/StoryViewer/BuildStory.scss';
import { TrackClick } from '@/analytics';
import { ByzzerMask } from '@/components/ByzzerMask/ByzzerMask';
import DownloadIcon from '@images/icons/storyIcons/downloadIcon.svg';
import { FLOATING_DATE_PICKER_FILTER_PARAMS } from '@/constants/table.constants';
import { ByzzerTable } from '@/components/ByzzerTable';
import { formatInTimeZone } from 'date-fns-tz';
import classnames from 'classnames';
import { ByzzerMenu } from '@/components/ByzzerMenu';
import { StoryRunsSummary } from '@/types/StoriesTypes';
import { ByzColDef } from '@/types/TableTypes';

type StoriesHistoryColId = 'isFavorite' | 'storiesStatus.id' | 'storyName' | 'storyType' | 'runBy' | 'runDate' | 'markets' | 'brands' | 'categories' | 'status' | 'actions';

const StoriesHistoryPage = ({}) => {
    const events = useEvents();
    const [loading, setLoading] = useState<boolean>(false);
    const {
        getStoryRuns,
        saveFavorites,
        getStoryRunById,
        deleteProductUsingActionId: deleteStoryHistory,
        downloadStory: downloadGeneratedSlide,
        getMySubscriptionUsage,
    } = useTenantApi();
    const navigate = useBetterNavigate();
    const baseClass = className('stories-history');
    const { user, subscription } = useUser();
    const [showModal, enableshowModal] = useState({
        displayModal: false,
        title: '',
        message: '',
        errorInfo: '',
    });
    const menuRefs = useRef<any[]>([]);
    const [storiesData, setStoriesData] = useState<StoryRunsSummary[]>([] as StoryRunsSummary[]);
    const storyStatus: Record<string, string> = {
        completed: 'Story Complete',
        processing: 'Story Running',
        failed: 'Story Failed',
    } as const;

    const gridRef = useRef<AgGridReact>(null);

    const filterParams: IDateFilterParams = {
        includeBlanksInEquals: false,
        includeBlanksInGreaterThan: false,
        includeBlanksInLessThan: false,
        includeBlanksInRange: false,
    };

    const defaultStoriesHistoryColDef = useMemo(
        () => ({
            filter: true, // make every column use 'text' filter by default
            sortable: true,
            floatingFilter: true, // enable floating filters by default
            resizable: true, //set each col resizable
            width: 200, // set every column width
            flex: 0, // flex: 1,
            autoHeight: false,
            wrapText: false,
            suppressMenu: true,
            lockVisible: true,
            sizeColumnsToFit: true,
        }),
        []
    );

    const [storiesHistoryColumnDefs] = useState<ByzColDef<StoryRunsSummary, StoriesHistoryColId>[]>([
        {
            headerName: '',
            pinned: 'left',
            colId: 'isFavorite',
            width: 80,
            maxWidth: 80,
            minWidth: 80,
            editable: false,
            sortable: false,
            filter: true,
            resizable: false,
            cellRenderer: ({ data }) => {
                return (
                    <FavoriteIcon
                        className={`${baseClass}__favorites--icon`}
                        trackClick={`Filter by favorites`}
                        selected={data?.isFavorite}
                        onClick={() => handleFavorites(data)}
                    />
                );
            },
        },
        ...(window.localStorage['show-story-id'] === 'true' ? [{ field: 'storiesStatus.id' as NestedFieldPaths<StoryRunsSummary, any, 1>, colId: 'storiesStatus.id' as const }] : []),
        {
            colId: 'storyName',
            headerName: 'Story Name',
            width: 300,
            valueGetter: ({ data }) => data.storyName,
            tooltipValueGetter: ({ data }) => data.storyName,
            pinned: 'left',
            disableHide: true
        },
        {
            colId: 'storyType',
            headerName: 'Type',
            width: 300,
            valueGetter: ({ data }) => data.storyType,
            tooltipValueGetter: ({ data }) => data.storyType,
        },
        {
            colId: 'runBy',
            headerName: 'Run By',
            valueGetter: ({ data }) => {
                return `${data.runBy}`;
            },
            tooltipValueGetter: ({ data }) => {
                return `${data.runBy}`;
            },
        },
        {
            ...FLOATING_DATE_PICKER_FILTER_PARAMS,
            headerName: 'Run Date',
            colId: 'runDate',
            field:'runDate',
            tooltipValueGetter: ({ data }) => {
                return `${data.runDate}`;
            },            
        },
        {
            headerName: 'Market(s)',
            colId: 'markets',
            width: 250,
            valueGetter: ({ data }) => {
                return `${data.marketName}`;
            },
            tooltipValueGetter: ({ data }) => {
                return `${data.marketName}`;
            },
        },
        {
            colId: 'brands',
            headerName: 'Brand(s)',
            width: 250,
            valueGetter: ({ data }) => {
                return `${data.brand}`;
            },
            tooltipValueGetter: ({ data }) => {
                return `${data.brand}`;
            },
        },
        {
            headerName: 'Category',
            colId: 'categories',
            width: 250,
            valueGetter: ({ data }) => {
                return `${data.categories}`;
            },
            tooltipValueGetter: ({ data }) => {
                return `${data.categories}`;
            },
        },
        {
            headerName: 'Status',
            colId: 'status',
            // width: 300,
            valueGetter: ({ data }) => {
                const status = data.storiesStatus?.results?.slideStatus;
                return `${storyStatus[status!]}`;
            },
            tooltipValueGetter: ({ data }) => {
                const status = data.storiesStatus?.results?.slideStatus;
                return `${storyStatus[status!]}`;
            },
        },
        {
            headerName: '',
            colId: 'actions',
            suppressMenu: true,
            sortable: false,
            filter: false,
            floatingFilter: false,
            resizable: false,
            pinned: 'right',
            width: 150,
            cellRenderer: ({ data }: { data: any }) => {
                const { id } = data.storiesStatus;
                return (
                    <div className={classnames(`${baseClass}__actions`)}>
                        {data.storiesStatus?.results?.slideStatus === 'completed' && (
                            <Tooltip
                                placement="top"
                                title={'Download'}
                                overlayClassName="tooltip-story .ant-tooltip-inner"
                            >
                                <span
                                    onClick={() => downloadStory(data.storiesStatus)}
                                    className={classnames(`${baseClass}__download_btn`)}
                                >
                                    <TrackClick
                                        name="story-download"
                                        data={{
                                            id: data.storiesStatus?.id,
                                            sku: data.storiesStatus?.productSku,
                                        }}
                                    >
                                        <img src={DownloadIcon} alt={'active download'} />
                                    </TrackClick>
                                </span>
                            </Tooltip>
                        )}

                        {data.storiesStatus?.results?.slideStatus === 'processing' && (
                            <img
                                src={DownloadIcon}
                                alt={'download disabled'}
                                className={classnames(`${baseClass}__disable_btn`)}
                            />
                        )}
                        {data.storiesStatus?.results?.slideStatus !== 'processing' && (
                            <>
                                <div
                                    key={id}
                                    className={classnames(`${baseClass}__menu-trigger`)}
                                    ref={(thisElement) => (menuRefs.current[String(id)] = { current: thisElement })}
                                />

                                <ByzzerMenu
                                    className={`${baseClass}__menu`}
                                    reference={menuRefs.current[String(id)]}
                                    offset={[-75, -25]}
                                    triggerTarget={menuRefs.current[String(id)]?.current}
                                    items={[
                                        {
                                            showIf:
                                                subscription?.active &&
                                                user?.role !== 'viewer' &&
                                                data.storiesStatus?.results?.slideStatus === 'completed',
                                            content: 'Run Again',
                                            toolTipHelpText: 'Run Again',
                                            onClick: function () {
                                                fetchStoryData(data.storiesStatus, 'editStory');
                                            },
                                        },
                                        {
                                            showIf: data.storiesStatus?.results?.slideStatus === 'failed',
                                            content: 'Run Again',
                                            toolTipHelpText: 'Run Again',
                                            onClick: function () {
                                                fetchStoryData(data.storiesStatus, 'modifySelections');
                                            },
                                        },

                                        {
                                            showIf:
                                                (subscription?.active &&
                                                    user?.role !== 'viewer' &&
                                                    data.storiesStatus?.results?.slideStatus === 'completed') ||
                                                data.storiesStatus?.results?.slideStatus === 'failed',
                                            content: 'Delete',
                                            trackClick: `Story History delete (${data.storiesStatus?.actionConfig?.storyCustomName}) clicked`,
                                            toolTipHelpText: 'Delete',
                                            onClick: function () {
                                                deleteStory(data.storiesStatus.id);
                                            },
                                        },
                                    ]}
                                />
                            </>
                        )}
                    </div>
                );
            },
        },
    ]);

    useEffect(() => {
        fetchAllData();
        initializeStoredValues();
    }, []);

    useEffect(() => {
        const [event] = events;
        if (event?.type === eventTypes.storyGenerated || event?.type === eventTypes.storyFailed) {
            fetchAllData();
        }
    }, [events]);

    async function handleFavorites(favRecord: StoryRunsSummary) {
        const storyId: number | undefined = favRecord?.storiesStatus?.id;
        const isfav: boolean = !favRecord.isFavorite;

        try {
            let updatedStoryRuns = gridRef.current?.props.rowData?.map((obj) => {
                if (obj.storiesStatus.id === storyId) {
                    return { ...obj, isFavorite: !obj.isFavorite };
                }
                return obj;
            });
            setStoriesData(updatedStoryRuns as StoryRunsSummary[]);
            await saveFavorites(storyId ?? 0, isfav);
        } catch (error: any) {
            console.log(error.message);
        }
    }

    const deleteStory = async (storyId) => {
        if (
            !(await confirm({
                title: 'Delete Story',
                content: <div className="byzzer-allocation-warning">Are you sure you want to delete this story?</div>,
                yesLabel: 'Yes',
                noLabel: 'No',
            }))
        ) {
            return;
        }
        setLoading(true);
        let delResponse = await deleteStoryHistory(storyId);
        setLoading(false);
        let alertContent = delResponse === 'Success' ? 'Story deleted successfully!' : 'Something went wrong';
        byzzerAlert({
            content: <div className="story_modal_alertText">{alertContent}</div>,
        });
        fetchAllData();
    };

    const fetchAllData = useCallback(async () => {
        setLoading(true);
        try {
            let responseData = {};
            let storiesHistory = await getStoryRuns();
            try {
                responseData = await historyDataParse(storiesHistory.products);
                setStoriesData(responseData as StoryRunsSummary[]);
            } catch (err) {
                throw err;
            }
        } catch (error: any) {
            console.log(error);
            const errorDetails = errorCodeDetails(error.code, 'stories');
            enableshowModal({
                ...showModal,
                title: errorDetails.title,
                displayModal: true,
                message: errorDetails.description,
                errorInfo: errorDetails.errorInfo,
            });
        }
        setLoading(false);
    }, []);

    const historyDataParse = async (historyData) => {
        let responseData: any = [];
        let datetime: any = [];
        historyData = historyData?.[0] === undefined ? [] : historyData;
        historyData.forEach((item, _index) => {
            let marketName = storyAppendMore(item?.actionConfig?.market, '', '', false);
            let productTitle = item?.product?.title === undefined ? '' : item.product.title;
            let storyName =
                item.actionConfig.storyCustomName === undefined ? productTitle : item.actionConfig.storyCustomName;
            if (Array.isArray(storyName)) {
                storyName = storyName[0];
            }
            storyName = storyName !== null ? storyName.replace(/[\\\["\\\]"]/g, '') : '';
            let storyResult = item.results;
            let brandName =
                item.actionConfig?.brand === undefined ? '' : storyAppendMore(item.actionConfig.brand, '', '', false);
            let categoriesData = storyAppendMore(item?.actionConfig?.category, '', '', false);
            let storyType = parseStoryType(item?.productSku);
            let productSku = item.actionConfig.productSku;
            let isFavorite = item.isFavorite;
            let runBy = item.runBy?.firstName + ' ' + item.runBy?.lastName;
            if (storyResult.slideStatus !== 'deleted') {
                responseData.push({
                    storyName: storyName,
                    storyType: storyType,
                    marketName: marketName,
                    dateTimeRun: item.actionDtm,
                    runDate: formatInTimeZone(new Date(item.actionDtm), 'America/New_York', 'yyyy-MM-dd'),
                    brand: brandName,
                    categories: categoriesData,
                    storiesStatus: item,
                    actionData: item,
                    productSku: productSku,
                    isFavorite: isFavorite,
                    runBy: runBy,
                });
                datetime.push(format(new Date(item.actionDtm), 'yyyy-MM-dd hh:mm'));
            }
        });
        return responseData;
    };

    const parseStoryType = (prouctSku) => {
        if (prouctSku === storySkus.categoryReview) {
            return 'Category Review';
        }
        if (prouctSku === storySkus.categoryManagement) {
            return 'Category Management';
        }
        if (prouctSku === storySkus.shopperSnapshot) {
            return 'Shopper Snapshot';
        }
        if (prouctSku === storySkus.brandReview) {
            return 'Brand Review';
        }
        if (prouctSku === storySkus.priceAndPromotion) {
            return 'Pricing and Promotion';
        }
        if (prouctSku === storySkus.categoryTrendAnalysis) {
            return 'Category Trend Analysis';
        }
    };

    const downloadStory = (story) => {
        try {
            byzzerAlert({
                content: <div className="story_modal_alertText">{alertMessages.storyDownloading}</div>,
            });
            const key = story.downloadKey;
            const productSku = story.productSku;

            if (['793', '842', '521'].includes(productSku)) {
                downloadGeneratedSlide(key);
            } else {
                downloadGeneratedSlide(key, 'blob');
            }
        } catch (err: any) {
            openErrorModal({
                title: 'Download Failed!',
                content: (
                    <>
                        <p>Fear not our engineering team is on the job.</p>
                    </>
                ),
                errorId: err.id,
            });
        }
    };

    // fetch story details
    const fetchStoryData = async (itemInfo, action) => {
        setLoading(true);
        localStorage['storyData'] = JSON.stringify(itemInfo);
        let actionValue = action;
        if (action === 'modifySelections') {
            actionValue = 'editStory';
            localStorage['modifySelections'] = true;
        }
        localStorage['action'] = actionValue; // viewStory, editStory
        let { configuration, sku, story } = await getStoryRunById(itemInfo.id);
        const usage = await getMySubscriptionUsage();
        localStorage.setItem('storySku', sku);
        navigate('/dashboard/story_builder', {
            params: {},
            state: {
                storyConfig: { ...story },
                defaultValues: { ...configuration.runConfig },
                remainingReportRuns: usage?.basicReports ? usage.basicReports.limit - usage.basicReports.used : 0,
            },
        });
        setLoading(false);
    };

    return (
        <>
            <ByzzerMask show={loading} loading={loading}>
                Loding Stories History
            </ByzzerMask>

            <ByzzerTable
                ref={gridRef} // Ref for accessing Grid's API
                rowData={storiesData} // Row Data for Rows
                columnDefs={storiesHistoryColumnDefs} // Column Defs for Columns
                defaultColDef={defaultStoriesHistoryColDef} // Column Defs for Columns
                readOnlyEdit={false}
                singleClickEdit={false}
                rowSelection="multiple"
                suppressRowClickSelection={true}
                tableArea={'storiesHistory'}
                enableColumnHideShow={true}
                enableSaveLayout={true}
                enableSaveSort={true}
            />
        </>
    );
};

export default StoriesHistoryPage;
