import './ProductRunConfigFilters.scss';
import React, { forwardRef, ReactNode, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import classnames from 'classnames';
import { FilterGroup, FilterSection } from '@/components/ConfigurationEditors/FilterGroup';
import {
    ByzzerInlineSelect,
    ByzzerRadio,
    ByzzerRadioGroup,
    ByzzerSelect,
    ByzzerTipIcon,
    useWizardStepContext,
} from '@byzzer/ui-components';
import {
    aggregationHierarchyInfoText,
    focusProductLevelLabelInfo,
    growthThresholdInfoText,
    salesThresholdInfoText,
} from '@/config/globalVars';
import { productLevelInfoHoverMessage } from '@/config/reportSelectorVars.config';
import { OptionalRequired } from '@/components/OptionalRequired/OptionalRequired';
import { ByzzerBrandSearch } from '@/components/ByzzerBrandSearch';
import { ByzzerCategorySelect } from '@/components/ByzzerCategorySelect';
import { AggregationLevelSelect } from '@/components/ConfigurationEditors/AggregationLevelSelect';
import { CharacteristicCriteriaBuilder } from '@/components/CharacteristicCriteriaBuilder';
import { ReportAggregationLevel, ReportRunConfig } from '@/types/ReportRun';
import { CharacteristicsDimensionSelect } from '@/components/CharacteristicsDimensionSelect/CharacteristicsDimensionSelect';
import ByzzerGrowthThresholdSelect from '@/components/ByzzerGrowthThresholdSelect/ByzzerGrowthThresholdSelect';
import { ByzzerSalesThresholdSelect } from '@/components/ByzzerSalesThresholdSelect';
import ByzzerPPGSelect from '@/components/ByzzerPPGSelect/ByzzerPPGSelect';
import {useTenantApi} from '@/hooks/useTenantApi';
import {
    ReportRunConfigWizardContext,
    ReportRunWizardState,
} from '@/components/ConfigurationEditors/ReportConfigurationEditor/ReportRunConfigWizard/ReportRunConfigWizardContext';
import { OmniCategorySelect } from '@/components/OmniCategorySelect';
import { OmniManufacturerSearch } from '@/components/OmniManufacturerSearch';
import { OmniBrandSearch } from '@/components/OmniBrandSearch';
import { ProductRunConfigOptions } from '@/types/RunConfigOptions';
import { LimitedLabel } from '@/components/LimitedLabel';
import { MinMaxMessage } from '@/components/MinMaxMessage';
import { SubCategorySelect } from '@/components/SubCategorySelect';
import { ProTipsSection } from '@/components/ProTipsSection';
import { useCharacteristicService } from '@/services/characteristic.service';
import { OmniBrandFamilySearch } from '@/components/OmniBrandFamilySearch';
import { OmniBrandLowSearch } from '@/components/OmniBrandLowSearch';
import AttributeGroupSelect from '@/components/AttributeGroup/AttributeGroupSelect';
import AttributeSelect from '@/components/Attribute/AttributeSelect';
import { SelectorLabelInfo } from '@/components/SelectorLabelInfo';

type ProductRunConfigFiltersValue = Partial<ReportRunConfig>;

export type ProductRunConfigFiltersProps = {
    name?: string;
    onChange?: (e: ByzzerChangeEvent<ProductRunConfigFiltersValue>) => void;
    onValidityChange?: (e: ByzzerValidityChangeEvent) => void;
    value?: ProductRunConfigFiltersValue;
    summary?: ReactNode;
} & Partial<Omit<ProductRunConfigOptions, 'type' | 'title'>> &
    Partial<Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>>;

const baseClassName = 'product-filters';

export type ProductRunConfigFiltersRef = {
    value: ProductRunConfigFiltersValue;
    isValid: boolean;
};

export type ReportAggregationLevelOptions = {
    display: string;
    value: ReportAggregationLevel;
}[];

const categoryTypeOptions: ReportAggregationLevelOptions = [
    { display: 'Category', value: 'category' },
    { display: 'Super Category', value: 'super_category' },
    { display: 'Department', value: 'department' },
    { display: 'Total Store', value: 'total_store' },
];

const omniProductLevelOptions = [
    { display: 'Brand', value: 'brand' },
    { display: 'Brand Family', value: 'brandFamily' },
    { display: 'Brand Low', value: 'brandLow' },
    { display: 'Manufacturer', value: 'manufacturer' },
];

export const MESSAGE_NO_CATEGORY_MATCH =
    'The categories you have selected do not contain any of your focus brands. Please select at least one category with a green dot.';

export const ProductRunConfigFilters = forwardRef<ProductRunConfigFiltersRef, ProductRunConfigFiltersProps>(
    (
        {
            className,
            value,
            onChange,
            onValidityChange,
            datatype,
            summary,
            name,

            includeAggregationLevel = false,
            includeFocusBrand = false, // todo: remove after configs are migrated
            includeFocusBrands = includeFocusBrand,
            includeBrands = false,
            includeOmniProducts = false,
            includeCompetitiveProducts = false,
            includeCategories = includeCompetitiveProducts,
            includeOmniCategories = false,
            includeProductSubcategory = false,
            includeCharacteristicDimensions = false,
            includeCharacteristics = false,
            includeKeyCharacteristic = false,
            includeKeyCharacteristics = includeKeyCharacteristic,
            includePPGs = false,
            includeProductLevel = false,
            thresholdType,
            includeGrowthThresholds = thresholdType === 'growth',
            includeSalesThresholds = thresholdType === 'sales',
            includeAttributeGroup = false,
            includeAttributes = false,

            excludeCharacteristicIsNot,

            maxBrands,
            minBrands,
            maxCategories,
            maxFocusBrands,
            minFocusBrands,
            maxCharacteristicDimensions,
            maxCharacteristics,
            maxGrowthThresholds,
            maxSalesThresholds,
            maxKeyCharacteristics,
            maxPPGs,
            maxOmniProducts,
            maxAttributeGroups,
            maxAttributes,

            thresholdRequired = false,
            requireCharacteristicDimensions = false,
            requireCharacteristics = false,
            requireFocusBrand = false,
            requireFocusBrands = requireFocusBrand,
            requireBrands = false,
            requireOmniProducts = false,
            requireCategories = false,
            requireProductSubcategory = false,
            requireOmniCategories = false,
            requireGrowthThresholds = false,
            requireSalesThresholds = false,
            requireKeyCharacteristics = false,
            requirePPGs = true, // TODO: configure in Alby, setting to true for now
            requireAggregationLevel = true, // TODO: configure in Alby, setting to true for now
            requireAttributeGroup = false,
            requireAttributes = false,
            ...props
        },
        ref
    ) => {
        const { getCategoriesByBrands, getCategoriesForTheme } = useTenantApi();
        const { value: contextValue, onChange: onContextChange, state: contextState, runType, sku } = useContext(
            ReportRunConfigWizardContext
        );
        const { showMessage, hideMessage } = useWizardStepContext();
        const { validateCharacteristicSelections } = useCharacteristicService();
        const [internalValue, setInternalValue] = useState<ProductRunConfigFiltersValue>({});
        const [categoriesToCheckForIntersect, setCategoriesToCheckForIntersect] = useState<string[]>([]);
        const includeThresholds = includeGrowthThresholds || includeSalesThresholds;
        const wasValid = useRef<boolean>(false);

        useEffect(() => {
            (async () => {
                const { focusBrands, themeSku } = internalValue;
                const categories = await Promise.all([
                    focusBrands?.length ? await getCategoriesByBrands(focusBrands) : [],
                    themeSku ? await getCategoriesForTheme(themeSku) : [],
                ]); // themeSku={'575'} can be used for testing...
                setCategoriesToCheckForIntersect(categories.flat());
            })();
        }, [internalValue.focusBrands, internalValue.themeSku]);

        useEffect(() => {
            const isValid = checkValidity();

            if (wasValid.current !== isValid) {
                onValidityChange?.({
                    name,
                    isValid,
                });

                wasValid.current = isValid;
            }
        }, [internalValue]);

        useEffect(() => {
            setInternalValue(contextValue ?? {});
        }, [contextValue]);

        useEffect(() => {
            if (value) {
                setInternalValue(value);
            }
        }, [value]);

        useEffect(() => {
            if (!internalValue.omniProductLevel?.length && (includeOmniCategories || includeOmniProducts)) {
                // temp workaround for BYZ-7884, where Omni product level shows up on summary screen for all reports.  Removed 'brand' from default value in wizard and set it here when loading.  fix belongs in summary ultimately.
                // handleOmniProductLevelChange({
                //     value: "brand",
                //     name: "omniProductLevel",
                //     data: {
                //         display: "Brand",
                //         value: "brand"
                //     }
                // })
            }
        }, []);

        useEffect(() => {
            const { focusBrands = [], categories = [], ppgId } = internalValue;
            const requiresCheck = Boolean(
                focusBrands.length && categories.length && includeFocusBrands && includeCategories
            );
            const missingIntersection =
                requiresCheck &&
                !internalValue.categories?.some((category) => categoriesToCheckForIntersect.includes(category));
            if (missingIntersection) {
                showMessage({
                    type: 'warning',
                    content: MESSAGE_NO_CATEGORY_MATCH,
                });
            } else {
                hideMessage();
            }
        }, [categoriesToCheckForIntersect, internalValue.categories]);

        useImperativeHandle(ref, () => ({
            get value() {
                return internalValue;
            },
            get isValid() {
                return checkValidity();
            },
        }));

        function handleChange(e: ByzzerChangeEvent<unknown>) {
            const fieldName = e.name as keyof ReportRunConfig;
        
            if (fieldName === 'attributes') {
                const customAttributeCodes: string[] = [];
        
                e.data.forEach((item) => {
                    if (item.isCustom && item.code) {
                        customAttributeCodes.push(item.code); // collect custom customAttributeCodes
                    }
                });

                customAttributeCodes.length &&  onContextChange?.('attributesCustomCodes', customAttributeCodes); // handle custom customAttributeCodes
                onContextChange?.(fieldName,  e.value, e.data); // handle regular attributes
            } else {
                onContextChange?.(fieldName, e.value, e.data); // handle other field changes
            }
        };

        function handleAggregationLevelChange(e: ByzzerChangeEvent<ReportAggregationLevel>) {
            handleCategoriesChange?.({
                // todo: see if this can be moved to a useEffect hook eventually.
                name: 'categories',
                value: [],
            });
            onContextChange?.(e.name as keyof ReportRunConfig, e.value, e.data);
        }

        function handleCategoriesChange(e: ByzzerChangeEvent<string[]>) {
            if (!e.value.length) {
                onContextChange?.('characteristics', [], []);
            }
            if (!internalValue.ppgId && includePPGs && e.value.length) {
                handleChange({ name: 'ppgId', value: -1 });
            }
            onContextChange?.(e.name as keyof ReportRunConfig, e.value, e.data);
        }

        // function handleBrandsChange(e: ByzzerChangeEvent<string[]>) {
        //     if (!e.value.length) {
        //         const chars = internalValue.characteristics?.map((charCondition) => {
        //             if (charCondition.characteristic === UPC_FIELD_NAME) {
        //                 return {
        //                     ...charCondition.value,
        //                     value: []
        //                 }
        //             }
        //             return charCondition
        //         })
        //         onContextChange?.('characteristics', chars);
        //     }
        //     onContextChange?.(e.name as keyof ReportRunConfig, e.value, e.data);
        // }

        function handleOmniProductLevelChange(e: ByzzerChangeEvent<unknown>) {
            onContextChange?.('omniFocusProducts', [], []);
            onContextChange?.(e.name as keyof ReportRunConfig, e.value, e.data);
        }

        function handleAttributeGroupChange(e: ByzzerChangeEvent<unknown>) {
            onContextChange?.('attributes', [], []);
            onContextChange?.(e.name as keyof ReportRunConfig, e.value, e.data);
        }

        function isAttributeSelectionRequired(): boolean {
            return Boolean(requireAttributes && internalValue.attributeGroup?.includes('All'))
        }

        function getPlaceholderForAttributeSelector(): string {
            if (requireAttributes) {
                if(!internalValue.attributeGroup?.length || internalValue.attributeGroup?.includes('All')) {
                    return 'Select from the list';
                }
                return 'ALL';
            }
            return !internalValue.attributeGroup?.length ? 'Select from the list' : 'ALL'
        }

        function validateAttributeSelector() {
                return Boolean((requireAttributes && (
                    (internalValue.attributeGroup?.includes('All') && (internalValue.attributesCustomCodes?.length || internalValue.attributes?.length)) ||
                    (!internalValue.attributeGroup?.includes('All') && internalValue.attributeGroup?.length)
                )) || internalValue.attributes?.length);
        }

        function validateFocusBrandLimit() {
            if (minFocusBrands) {
                return internalValue.focusBrands?.length! >= minFocusBrands;
            } else {
                return Boolean(internalValue.focusBrands?.length);
            }
        }

        function validateBrandLimit() {
            if (minBrands) {
                return internalValue.brands?.length! >= minBrands;
            } else {
                return Boolean(internalValue.brands?.length);
            }
        }

        function checkValidity(): boolean {            
            const characteristicsAndOrKeyCharacteristicsAreValid = validateCharacteristicSelections(
                internalValue.characteristics!,
                { requireCharacteristics, requireKeyCharacteristics }
            );
            return Boolean(
                (!requireCharacteristicDimensions || internalValue.productDimensions?.length) &&
                    characteristicsAndOrKeyCharacteristicsAreValid &&
                    (!requireBrands || validateBrandLimit()) &&
                    (!requireFocusBrands || validateFocusBrandLimit()) &&
                    (!requireCategories || internalValue.categories?.length) &&
                    (!requireOmniCategories || internalValue.categories?.length) &&
                    (!requireOmniProducts || internalValue.omniFocusProducts?.length) &&
                    (!requireSalesThresholds || internalValue.salesThresholds?.length) &&
                    (!requireGrowthThresholds || internalValue.growthThresholds) &&
                    (!requireAttributeGroup || internalValue.attributeGroup?.length) &&
                    (validateAttributeSelector())
            );
        }

        // @ts-ignore
        return (
            <FilterGroup className={classnames(baseClassName, className)}>
                {summary && <div className={`${baseClassName}__summary`}>{summary}</div>}
                <FilterSection
                    onlyRenderIf={
                        includeFocusBrands ||
                        includeBrands ||
                        includeCategories ||
                        includeAggregationLevel ||
                        includeProductSubcategory
                    }
                >
                    <ByzzerBrandSearch
                        onlyRenderIf={includeFocusBrands}
                        name={'focusBrands'}
                        maxSelections={maxFocusBrands}
                        value={internalValue.focusBrands}
                        onChange={handleChange}
                        label={
                            <SelectorLabelInfo
                                selectorCode={'focusBrands'}
                                sku={sku as string}
                                max={maxFocusBrands}
                                min={minFocusBrands}
                                required={requireFocusBrands}
                                includeSuffix={!requireFocusBrands}
                            />
                        }
                    />
                    <ByzzerCategorySelect
                        name={'categories'}
                        onlyRenderIf={includeCategories}
                        value={internalValue.categories}
                        onChange={handleCategoriesChange}
                        placeholder={'Select from the list'}
                        allowClear={internalValue.categorySelectionAggregationLevel !== 'total_store'}
                        disabled={internalValue.categorySelectionAggregationLevel === 'total_store'}
                        categorySelectionAggregationLevel={internalValue.categorySelectionAggregationLevel}
                        categoriesToCheckForIntersect={categoriesToCheckForIntersect}
                        label={
                            <>
                                {/* to cross verify in alby productmetadata once while unit testing */}
                                {runType === 'adhoc' ? (
                                    <>
                                        'Select your'
                                        <ByzzerInlineSelect // TODO - fix spacing without nbsp
                                            name={'categorySelectionAggregationLevel'}
                                            onChange={handleAggregationLevelChange}
                                            value={internalValue.categorySelectionAggregationLevel}
                                            options={categoryTypeOptions}
                                        />
                                        <SelectorLabelInfo
                                            sku={sku as string}
                                            selectorCode={'categoriesAdhoc'}
                                            max={maxCategories}
                                            showLabel={false}
                                        />
                                    </>
                                ) : (
                                    <>
                                        Select your
                                        <ByzzerInlineSelect // TODO - fix spacing without nbsp
                                            name={'categorySelectionAggregationLevel'}
                                            onChange={handleAggregationLevelChange}
                                            value={internalValue.categorySelectionAggregationLevel}
                                            options={categoryTypeOptions}
                                        />
                                        <SelectorLabelInfo
                                            sku={sku as string}
                                            selectorCode={'categories'}
                                            max={maxCategories}
                                            showLabel={false}
                                            required={requireCategories}
                                            includeSuffix={!requireCategories}
                                        />
                                    </>
                                )}
                            </>
                        }
                        shouldDisplayIntersectIndicators={
                            requireFocusBrand || categoriesToCheckForIntersect?.length > 0
                        }
                        maxSelections={maxCategories}
                        listAllCategoriesInOptions={runType === 'adhoc'} // for adhoc reports, user can select from all categories.
                        groupOptionsByBrandCoverage={requireFocusBrand || categoriesToCheckForIntersect?.length > 0}
                    />
                    {/* TODO: check competitive brand selection */}
                    {/* Sub-Category (this section might also include competitive brand selection) */}
                    <SubCategorySelect
                        categories={internalValue.categories}
                        onChange={handleChange}
                        onlyRenderIf={includeProductSubcategory}
                        disabled={!internalValue.categories?.length}
                        placeholder={'All'}
                        // todo: use the requireSubcategories flag instead of hard coding this to true
                        label={
                            <>
                                <SelectorLabelInfo
                                    selectorCode={'productSubCategory'}
                                    required={requireProductSubcategory}
                                    includeSuffix={!requireProductSubcategory}
                                    sku={sku as string}
                                />
                            </>
                        }
                        value={internalValue.subcategories}
                        name={'subcategories'}
                    />
                    <ByzzerBrandSearch
                        onlyRenderIf={includeBrands}
                        name={'brands'}
                        value={internalValue.brands as string[]}
                        onChange={handleChange}
                        label={
                            <>
                                <SelectorLabelInfo
                                    selectorCode={'brands'}
                                    sku={sku as string}
                                    required={requireBrands}
                                    min={minBrands}
                                    max={maxBrands}
                                    includeSuffix={!requireBrands}
                                />
                            </>
                        }
                        categories={internalValue.categories}
                        maxSelections={maxBrands}
                    />
                    <AggregationLevelSelect
                        name={'aggregationLevel'}
                        onlyRenderIf={includeAggregationLevel}
                        value={internalValue.aggregationLevel} // matches name now
                        onChange={handleChange}
                        allowClear={!requireAggregationLevel}
                        label={
                            <SelectorLabelInfo
                                selectorCode={'aggregationLevel'}
                                required={requireAggregationLevel}
                                includeSuffix={!requireAggregationLevel}
                                sku={sku as string}
                            />
                        }
                    />
                    <AttributeGroupSelect
                        name={'attributeGroup'}
                        onlyRenderIf={includeAttributeGroup}
                        value={internalValue.attributeGroup}
                        onChange={handleAttributeGroupChange}
                        label={
                            <>
                                <SelectorLabelInfo
                                    selectorCode={'attributeGroup'}
                                    required={requireAttributeGroup}
                                    includeSuffix={!requireAttributeGroup}
                                    sku={sku as string}
                                    max={maxAttributeGroups}
                                />
                            </>
                        }
                        placeholder={'Select from the list'}
                        categories={internalValue.categories}
                        maxSelections={maxAttributeGroups}
                        aggregationLevel={internalValue.categorySelectionAggregationLevel}
                        needAllAttributeGroup={requireAttributes}
                    />
                    <AttributeSelect
                        name={'attributes'}
                        onlyRenderIf={includeAttributes}
                        value={internalValue.attributes}
                        onChange={handleChange}
                        label={
                            <>
                                <SelectorLabelInfo
                                    selectorCode={'attributes'}
                                    sku={sku as string}
                                    max={maxAttributes}
                                    required={isAttributeSelectionRequired()}
                                />
                            </>
                        }
                        placeholder={getPlaceholderForAttributeSelector()}
                        attributeGroup={internalValue.attributeGroup}
                        disabled={!internalValue.attributeGroup?.length}
                        maxSelections={maxAttributes}
                        aggregationLevel={internalValue.categorySelectionAggregationLevel}
                        categories={internalValue.categories}
                        loadCharacteristics={internalValue.attributeGroup?.includes('All')}
                        includeUpcOption={true} // should eventually be set in Alby
                    />
                </FilterSection>
                <FilterSection onlyRenderIf={includeOmniCategories || includeOmniProducts}>
                    <OmniCategorySelect
                        onlyRenderIf={includeOmniCategories}
                        name={'categories'}
                        value={internalValue.categories}
                        onChange={handleChange}
                        label={
                            <>
                                <SelectorLabelInfo
                                    selectorCode={'omniCategories'}
                                    sku={sku as string}
                                    required={requireOmniCategories}
                                    includeSuffix={!requireOmniCategories}
                                />
                            </>
                        }
                        placeholder={'Select from the list'}
                    />
                    <ByzzerSelect
                        label={
                            <>
                                {/* Todo: Alby config needs update to make this required  */}

                                <SelectorLabelInfo
                                    selectorCode={'omniProducts'}
                                    sku={sku as string}
                                    required={requireOmniProducts}
                                    includeSuffix={!requireOmniProducts}
                                />
                            </>
                        }
                        name={'omniProductLevel'}
                        options={omniProductLevelOptions}
                        value={internalValue.omniProductLevel}
                        onChange={handleOmniProductLevelChange}
                        placeholder={'Select a product level'}
                        disabled={!internalValue.categories?.length}
                        allowClear={!requireOmniProducts}
                    />
                    <OmniBrandSearch
                        name={'omniFocusProducts'}
                        onlyRenderIf={
                            includeOmniProducts &&
                            (internalValue.omniProductLevel === 'brand' || !internalValue.omniProductLevel)
                        }
                        value={internalValue.omniFocusProducts}
                        onChange={handleChange}
                        label={
                            <>
                                <SelectorLabelInfo
                                    selectorCode={'omniBrands'}
                                    sku={sku as string}
                                    required={requireOmniProducts}
                                    max={maxOmniProducts}
                                    includeSuffix={!requireOmniProducts}
                                />
                            </>
                        }
                        maxBrands={maxOmniProducts}
                        placeholder={'Search for brands'}
                        disabled={!internalValue.omniProductLevel || !internalValue.categories?.length}
                        omniCategories={internalValue.categories!}
                    />

                    <OmniBrandFamilySearch
                        name={'omniFocusProducts'}
                        onlyRenderIf={includeOmniProducts && internalValue.omniProductLevel === 'brandFamily'}
                        value={internalValue.omniFocusProducts}
                        onChange={handleChange}
                        label={
                            <>
                                <SelectorLabelInfo
                                    selectorCode={'omniBrandFamily'}
                                    sku={sku as string}
                                    required={requireOmniProducts}
                                    max={maxOmniProducts}
                                    includeSuffix={!requireOmniProducts}
                                />
                            </>
                        }
                        maxBrandsFamily={maxOmniProducts}
                        disabled={!internalValue.omniProductLevel || !internalValue.categories?.length}
                        placeholder={'Search for brand family'}
                        omniCategories={internalValue.categories!}
                    />

                    <OmniBrandLowSearch
                        name={'omniFocusProducts'}
                        onlyRenderIf={includeOmniProducts && internalValue.omniProductLevel === 'brandLow'}
                        value={internalValue.omniFocusProducts}
                        onChange={handleChange}
                        label={
                            <>
                                <SelectorLabelInfo
                                    selectorCode={'omniBrandLow'}
                                    sku={sku as string}
                                    required={requireOmniProducts}
                                    max={maxOmniProducts}
                                    includeSuffix={!requireOmniProducts}
                                />
                            </>
                        }
                        maxBrandsLow={maxOmniProducts}
                        disabled={!internalValue.omniProductLevel || !internalValue.categories?.length}
                        placeholder={'Search for brand low'}
                        omniCategories={internalValue.categories!}
                    />

                    <OmniManufacturerSearch
                        name={'omniFocusProducts'}
                        onlyRenderIf={includeOmniProducts && internalValue.omniProductLevel === 'manufacturer'}
                        value={internalValue.omniFocusProducts}
                        onChange={handleChange}
                        label={
                            <>
                                <SelectorLabelInfo
                                    selectorCode={'omniManufacturer'}
                                    sku={sku as string}
                                    required={requireOmniProducts}
                                    max={maxOmniProducts}
                                    includeSuffix={!requireOmniProducts}
                                />
                            </>
                        }
                        maxSelections={maxOmniProducts}
                        disabled={!internalValue.omniProductLevel || !internalValue.categories?.length}
                        placeholder={'Search for manufacturers'}
                        omniCategories={internalValue.categories!}
                    />
                </FilterSection>

                <FilterSection onlyRenderIf={includeCharacteristicDimensions}>
                    <CharacteristicsDimensionSelect
                        name={'productDimensions'}
                        categories={internalValue.categories}
                        onChange={handleChange}
                        value={internalValue?.productDimensions}
                        maxSelections={maxCharacteristicDimensions}
                        sku={sku}
                        required={requireCharacteristicDimensions}
                    />
                </FilterSection>
                <FilterSection onlyRenderIf={includeThresholds}>
                    <ProTipsSection
                        includeThresholds={includeThresholds}
                        includeSalesThresholds={includeSalesThresholds}
                    />
                    {/* todo: verify the name of the property for these or just handle it on the server side */}
                    <ByzzerSalesThresholdSelect
                        name={'salesThresholds'}
                        onChange={handleChange}
                        label={
                            <>
                                <SelectorLabelInfo
                                    selectorCode={'salesThreshold'}
                                    sku={sku as string}
                                    required={requireSalesThresholds}
                                    includeSuffix={!requireSalesThresholds}
                                    max={maxSalesThresholds}
                                />
                            </>
                        }
                        value={internalValue.salesThresholds}
                        onlyRenderIf={includeSalesThresholds}
                        maxSelections={maxSalesThresholds}
                    />
                    <ByzzerGrowthThresholdSelect
                        name={'growthThresholds'} // needs to be plural to align with current wizard, but could investigate changing to singular
                        onChange={handleChange}
                        label={
                            <>
                                <SelectorLabelInfo
                                    selectorCode={'growthThreshold'}
                                    sku={sku as string}
                                    required={requireGrowthThresholds}
                                    includeSuffix={!requireGrowthThresholds}
                                />
                            </>
                        }
                        value={internalValue.growthThresholds}
                        onlyRenderIf={includeGrowthThresholds}
                    />
                </FilterSection>
                <FilterSection onlyRenderIf={includePPGs}>
                    <ByzzerPPGSelect
                        name={'ppgId'}
                        onChange={handleChange}
                        value={internalValue.ppgId}
                        categories={internalValue?.categories}
                        required={requirePPGs}
                        sku={sku}
                        maxPPG={maxPPGs}
                    />
                </FilterSection>
                <FilterSection onlyRenderIf={includeCharacteristics}>                    
                    <SelectorLabelInfo
                        selectorCode={'characteristicFilters'}
                        required={requireCharacteristics}
                        includeSuffix={!requireCharacteristics}
                        sku={sku as string}
                        max={maxCharacteristics}
                        isLabelBold={true}
                    />
                    <CharacteristicCriteriaBuilder
                        name={'characteristics'}
                        value={internalValue.characteristics}
                        categories={internalValue.categories}
                        maxConditions={maxCharacteristics}
                        onChange={handleChange}
                        excludeCharacteristicIsNot={excludeCharacteristicIsNot}
                        includeUpcOption={true} // should eventually be set in Alby
                        // brand={internalValue.focusBrands?.length === 1 ? internalValue?.focusBrands?.[0] : undefined}
                    />
                </FilterSection>
                <FilterSection onlyRenderIf={includeKeyCharacteristics}>
                    <div className={`${baseClassName}__key-chars`}>
                        <>
                            <SelectorLabelInfo
                                sku={sku as string}
                                selectorCode={'keyCharecteristics'}
                                required={requireKeyCharacteristics}
                                includeSuffix={!requireKeyCharacteristics}
                                isLabelBold={true}
                                max={maxKeyCharacteristics}
                            />
                        </>
                    </div>                   
                    <CharacteristicCriteriaBuilder
                        name={'characteristics'}
                        joinText={'or'}
                        maxConditions={maxKeyCharacteristics}
                        value={internalValue.characteristics}
                        categories={internalValue.categories}
                        onChange={handleChange}
                        excludeCharacteristicIsNot={excludeCharacteristicIsNot}
                    />
                </FilterSection>
            </FilterGroup>
        );
    }
);

ProductRunConfigFilters.displayName = 'ProductRunConfigFilters';
