interface OutlinedCrossBoxIconProps {
    color?: string;
    size?: number;
}

const OutlinedCrossBoxIcon: React.FC<OutlinedCrossBoxIconProps> = ({ color = '#AA213F', size = 20 }) => {
    const strokeWidth = size / 15;
    const padding = size / 5;
    const cornerRadius = size / 10;
    const pathPoints = [
        `M${padding + cornerRadius} ${padding + cornerRadius} L${size - padding - cornerRadius} ${
            size - padding - cornerRadius
        }`,
        `M${padding + cornerRadius} ${size - padding - cornerRadius} L${size - padding - cornerRadius} ${
            padding + cornerRadius
        }`,
    ];

    return (
        <svg width={size} height={size} xmlns="http://www.w3.org/2000/svg">
            <rect x="0" y="0" width={size} height={size} fill="transparent" stroke={color} strokeWidth={4} />
            {pathPoints.map((d, index) => (
                <path
                    key={index}
                    d={d}
                    fill="none"
                    stroke={color}
                    strokeWidth={strokeWidth}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            ))}
        </svg>
    );
};

export default OutlinedCrossBoxIcon;
