import { forwardRef, ReactNode, useEffect, useState, useCallback } from 'react';
import classnames from "classnames";
import { ByzzerChangeEventHandler, ByzzerSearch, WithValue } from '@byzzer/ui-components';
import {useTenantApi} from '@/hooks/useTenantApi';
import { useApp } from '@/contexts/UserContext';

const baseClassName = 'omni-manufacturer-search';

export type OmniManufacturerSearchProps = {
    value?: string[];
    className?: string;
    name?: string;
    label?: ReactNode;
    onChange?: ByzzerChangeEventHandler<string[]>;
    placeholder?: string;
    maxSelections?: number;
    disabled?: boolean;
    omniCategories?: string[];
} & OnlyRenderableIf;

export const OmniManufacturerSearch = forwardRef<WithValue<string[]>, OmniManufacturerSearchProps>(
    (
        {
            className,
            label,
            name,
            onChange,
            value,
            placeholder = 'Search for a focus Manufacturer',
            disabled,
            maxSelections = 10,
            onlyRenderIf = true,
            omniCategories,
            ...props
        },
        ref
    ) => {
        if(onlyRenderIf === false) return <></>;
        const { findManufacturersInCategories } = useTenantApi();
        const [internalValue, setInternalValue] = useState<string[]>();
        const { omniCategories: omniCategoriesFromSubscription }  = useApp();
        const omniCategoriesList = omniCategories ?? omniCategoriesFromSubscription;

        useEffect(() => {
            setInternalValue(value)
        }, [value])

        const searchManufacturers = useCallback(async (searchText: string): Promise<string[]> => {
            return findManufacturersInCategories({
                searchTerm: searchText, 
                categories: omniCategoriesList
            });
        }, [omniCategoriesList]);

        const handleChange = (e: any) => {
            const newManufacturerPayload = {
                value: e.value.map((manufacturer) => manufacturer.value),
                name,
                data: e.data
            }
            if (onChange) {
                onChange?.(newManufacturerPayload)
            } else {
                setInternalValue(e.data)
            }
        }

        return (
            <ByzzerSearch
                ref={ref}
                name={name}
                label={label}
                search={searchManufacturers}
                placeholder={placeholder}
                value={internalValue}
                maxSelections={maxSelections}
                className={classnames(baseClassName, className)}
                onChange={handleChange}
                disabled={disabled}
            />
        );
    }
);
export default OmniManufacturerSearch;

OmniManufacturerSearch.displayName = 'OmniManufacturerSearch';