import './ReportRunConfigSummary.scss';
import React, { ReactNode, useContext } from 'react';
import classnames from 'classnames';
import {
    CharacteristicCondition,
    RunConfigMarket,
    ReportRunConfig,
    TimePeriod,
    DemographicCondition,
    CharacteristicDimension,
    ComparisonMarket,
    DemographicDimension,
    chartDataConfigurationType,
} from '@/types/ReportRun';
import { ReportRunConfigWizardContext } from '@/components/ConfigurationEditors/ReportConfigurationEditor/ReportRunConfigWizard/ReportRunConfigWizardContext';
import format from 'date-fns/format';
import { getPPGDisplayValue } from '@/services/ppg.service';
import { growthThresholdOptions } from '@/constants/threshold.constants';
import { omniProductLevelOptions } from '@/constants/omniProducts.constants';
import { ByzzerDate } from '@byzzer/ui-components';
import { ByzzerChip } from '@/components/ByzzerChip';

export type ReportRunSummaryProps = {
    runConfig?: Partial<ReportRunConfig>;
} & React.HTMLAttributes<HTMLDivElement>;

const baseClassName = 'report-run-summary';
export type SummarySectionConfig = {
    title?: string;
    prop: keyof ReportRunConfig;
    toValue?: (value: any) => string | string[] | ReactNode;
    toTitle?: (runConfig: Partial<ReportRunConfig>) => ReactNode;
};

const SUMMARY_SECTIONS: SummarySectionConfig[] = [
    { title: 'Brands', prop: 'brands' },
    { title: 'Focus Brand', prop: 'focusBrands' },
    {   title: 'Product Level',
        prop: 'omniProductLevel',
        toValue(omniProductLevel: string) {
            return omniProductLevelOptions.find((option) => {
                return option.value === omniProductLevel;
            })?.display
        },
    },
    {
        prop: 'omniFocusProducts',
        toTitle(runConfig) {            
            switch (runConfig.omniProductLevel) {
                case 'brand':
                    return 'Brands';
                case 'manufacturer':
                    return 'Manufacturers';
                case 'brandFamily':
                    return 'Brand Families';
                case 'brandLow':
                    return 'Brand Lows';
            }
        },
    },
    { title: 'Categories', prop: 'categories' },
    { title: 'Attribute Groups', prop: 'attributeGroup' },
    {
        title: 'Attributes',
        prop: 'attributes',
        toValue(attribute) {
            return attribute.map((item) => {
                // We are setting characteristics in attribute for wellness report,
                // so in AttributeSelect.tsx we are creating name by appending (Custom)-code-1234 that's why
                // here we have to separate that to show in UI, This is under BYZ-13492
                if (item.includes('(Custom)')) {
                    return item.split('-code-')?.[0];
                }
                return item;
            });
        }
    },
    { title: 'Sub-Categories', prop: 'subcategories' },
    {
        title: 'PPG Definition',
        prop: 'ppgId',
        toValue(ppgId: number) {
            return getPPGDisplayValue(ppgId);
        },
    },
    // { title: 'Aggregation Level', prop: 'aggregationLevel' },
    // { title: 'Growth Threshold', prop: 'growthThreshold' },
    {
        title: 'Growth Threshold',
        prop: 'growthThresholds',
        toValue(growthThreshold: string | string[]) {
            // Todo: Can remove support to handle string value later once we are sure there is no old data for growth threshold
            if (Array.isArray(growthThreshold)) {
                return growthThresholdOptions
                    .filter((gtOption) => growthThreshold.some((gt) => gt === gtOption?.value))
                    .map((gtValue) => `${gtValue?.display}`);
            } else
                return growthThresholdOptions?.filter((gtOption) => gtOption?.value === growthThreshold)?.[0]?.display;
        },
    },
    { title: 'Sales Threshold', prop: 'salesThresholds' },
    {
        title: 'Characteristic Dimensions',
        prop: 'productDimensions',
        toValue(dimensions: CharacteristicDimension[]) {
            return dimensions.map((dimension) => `${dimension?.characteristic}`);
        },
    },
    {
        title: 'Characteristics',
        prop: 'characteristics',
        toValue(charateristics: CharacteristicCondition[]) {
            return charateristics.map(
                (characteristic) =>
                    `${characteristic?.characteristicDisplayValue} ${
                        characteristic?.condition
                    } ${characteristic?.value.join(', ')}`
            );
        },
    },
    {
        title: 'Demographic Dimensions',
        prop: 'demographicDimensions',
        toValue(demographicDimensions: DemographicDimension[]) { // As part of BYZ-7998, this should now only be in DemographicDimension format. Falling back on string just in case
            return demographicDimensions.map((demographicDimension) => `${(demographicDimension as DemographicDimension)?.display ?? demographicDimension}`);
        },
    },
    {
        title: 'Demographics',
        prop: 'demographics',
        toValue(demographics: DemographicCondition[]) {
            return demographics.map(
                (demographic) =>
                    `${demographic?.data?.display} ${demographic?.condition} ${demographic?.value.join(', ')}`
            );
        },
    },
    // {title: 'Data Type', prop: 'datatype'},
    {
        title: 'Markets',
        prop: 'markets',
        toValue(markets: RunConfigMarket[]) {
            return markets.filter(item=>!item?.disabledMarketNode).map((market) =>
                market?.remainingMarketRunConfig?.name
                    ? `${market?.name} vs ${market?.remainingMarketRunConfig?.name}`
                    : market?.name
            );
        },
    },
    {
        title: 'Comparison Market',
        prop: 'comparisonMarket',
        toValue(comparisonMarket: ComparisonMarket) {
            if (!comparisonMarket) {
                return;
            }
            return `${comparisonMarket?.channel} (${comparisonMarket?.marketType})`;
        },
    },
    {
        title: 'Sub-Markets',
        prop: 'subMarkets',
        toValue(markets: RunConfigMarket[]) {
            return markets.map(({ name }) => `${name}`);
        },
    },
    // { title: 'Dimension', prop: 'reportDimension' },
    // {title: 'Theme', prop: 'themeSku'},
    {
        title: 'Time Period',
        prop: 'timePeriod',
        toValue(tp: TimePeriod) {
            let returnVal;
            if (!tp ) return '';
            if( ( tp.type === 'omni_custom' || tp.type === 'week_ending') && ( !tp.period?.endDate )) return '';
       
            if (tp.type === 'week_ending') {
                returnVal = 
                <>
                    {`${tp.period?.weeks ?? 0} week${tp.period?.weeks! > 1 ? 's' : ''} ending `}
                    <ByzzerDate date={tp.period?.endDate!} format={'yyyy-MM-dd'} timeZone="UTC" />
                </> 
            }else if( tp.type === 'omni_custom' ){               
                returnVal = 
                <>
                    {`${tp.period?.months ?? 0} month${tp.period?.months! > 1 ? 's' : ''} ending `}
                    <ByzzerDate date={tp.period?.endDate!} format={'yyyy-MM-dd'} timeZone="UTC"  />
                </>
            }else {
                returnVal = tp?.period;
            }
            return returnVal;
        },
    },
    {
        title: "",
        prop: "chartDataConfigurationType",
        toValue(chartDataConfigurationType: chartDataConfigurationType) {
            return `Chart Configuration Type: ${chartDataConfigurationType}`
        },
    }
];

export function ReportRunConfigSummary({ className, runConfig, ...props }: ReportRunSummaryProps) {
    const { value: contextRunConfig } = useContext(ReportRunConfigWizardContext);
    const summaryRunConfig: Partial<ReportRunConfig> = contextRunConfig ?? runConfig;

    return (
        <div className={classnames(baseClassName, className)} {...props}>
            {SUMMARY_SECTIONS.map((section) => {
                const { prop, toValue = (v) => v, toTitle } = section;
                // if prop exist and if it is not undefined in the object say chartDataConfigurationType doesnot exist for all reports
                if( prop in summaryRunConfig && summaryRunConfig[prop] ){                    
                    const value: any = toValue(summaryRunConfig?.[prop]);
                
                    // either use toTitle funtion if its present or use default title from SUMMARY_SECTIONS array
                    const title: ReactNode = toTitle?.(summaryRunConfig) ?? section.title;
                    if (!value || (value.hasOwnProperty('length') && !value.length)) return;
                    const values = Array.isArray(value)
                        ? value.map((value) => (
                            <ByzzerChip key={value}>
                                {value}
                            </ByzzerChip>
                        )) : (
                            <ByzzerChip>
                                {value}
                            </ByzzerChip>
                        );
                    return (
                        <div className={`${baseClassName}__section`} key={prop}>
                            <h1 className={`${baseClassName}__section-title`}>{title}</h1>
                            <div className={`${baseClassName}__value`}>{values}</div>
                        </div>
                    );
                }                
            })}
        </div>
    );
}

export default ReportRunConfigSummary;
