import { forwardRef, ReactNode, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { ByzzerChangeEventHandler, ByzzerSelect, WithValue, ByzzerSelectOption } from '@byzzer/ui-components';
import { useAttributeService } from '@/services/attribute.service';
import { useTenantApi } from '@/hooks';
import { upcCharacteristicOption } from '@/config/globalVars';
import { useApp } from '@/contexts/UserContext';

export type ByzzerAttributeSelectProps = {
    name?: string;
    label?: ReactNode;
    onChange?: ByzzerChangeEventHandler<string[]>;
    value?: string[];
    disabledOptions?: string[];
    placeholder?: string;
    className?: string;
    disabled?: boolean;
    maxSelections?: number;
    attributeGroup?: string[];
    aggregationLevel?: string;
    categories?:string[];
    loadCharacteristics?: boolean;
    includeUpcOption?: boolean;
} & OnlyRenderableIf;

export type CharacteristicOptionForAttributes = {
    value: string;
    display: string;
    code: string;
    isCustom?: boolean;
};

export const AttributeSelect = forwardRef<WithValue<string[]>, ByzzerAttributeSelectProps>(
    (
        {
            onChange,
            name,
            label,
            className,
            disabledOptions = [],
            placeholder,
            value,
            disabled,
            onlyRenderIf,
            maxSelections,
            attributeGroup,
            aggregationLevel,
            categories,
            loadCharacteristics,
            includeUpcOption
        },
        ref
    ) => {
        const [internalValue, setInternalValue] = useState<string[]>();
        if (onlyRenderIf === false) return <></>;

        const [attributeOptions, setAttributeOptions] = useState<ByzzerSelectOption[]>([]);
        const { getAttributes, getAttributeGroups } = useAttributeService();
        const { getCharacteristicsForCategories } = useTenantApi();      
        const { customCharacteristics: customCharacteristicsContext } = useApp();

        useEffect(() => {
            setInternalValue(value);
        }, [value]);

        useEffect(() => {
            (async (attributeGroup) => {
                await loadAttributeOptions(attributeGroup, aggregationLevel);
            })(attributeGroup);
        }, [attributeGroup]);

        async function loadAttributeOptions(attributeGroup?: string[], aggregationLevel?: string) {
            if (!attributeGroup?.length) {
                setAttributeOptions([]);
                return;
            }

            if (loadCharacteristics && categories?.length) {
                const attributeGroups = await getAttributeGroups(categories!, aggregationLevel);
                const [attributes, characterSticOptions] = await Promise.all([
                    getAttributes(attributeGroups?.map((item)=> (item.value)), aggregationLevel),
                    fetchCharacteristicsForCategories()
                ]);
                
                // Combine the options and filter out duplicates based on 'display'
                const options = [...attributes, ...characterSticOptions];
                const unique = options.filter(
                    (obj, index) =>
                        options.findIndex((item) => item.display === obj.display) === index
                );

                const sortedOptions = unique.sort((charA, charB) => charA.display.localeCompare(charB.display, 'en', {sensitivity: 'base'}));
                setAttributeOptions(sortedOptions);
            } else {
                // Normal scenario
                const options = await getAttributes(attributeGroup!, aggregationLevel);
                setAttributeOptions(options);
            }
        }

        const fetchCharacteristicsForCategories = async () => {
            const characteristicsForCategories = [
                ...(await getCharacteristicsForCategories(categories!)),
                ...(includeUpcOption ? [upcCharacteristicOption] : [])
            ];
            const basicCharacteristicsValue: CharacteristicOptionForAttributes[] = characteristicsForCategories.map(
                (characteristic) => ({
                    // TODO: From DoD refactor merge - see what the correct values are for these
                    value: characteristic?.displayName,
                    display: characteristic?.displayName,
                    code: characteristic?.code,
                    isCustom: false,
                })
            );

            const matchingCustomCharacteristics: CharacteristicOptionForAttributes[] = customCharacteristicsContext
                .filter((item) => categories?.map((category) => category)?.some((i) => item?.categories?.includes(i)))
                .map((customCharacteristic) => {
                    return {
                        value:`${customCharacteristic.label} (Custom)-code-${String(customCharacteristic.id)}`,
                        display: `${customCharacteristic.label} (Custom)`,
                        code: String(customCharacteristic.id),
                        isCustom: true,
                    }
                });

            return [...matchingCustomCharacteristics, ...basicCharacteristicsValue];
        };

        function handleChange(e: any) {
            onChange?.({
                value: e?.value,
                name,
                data: e?.data,
            });
            setInternalValue(e.value);
        }

        return (
            <ByzzerSelect
                ref={ref}
                name={name}
                className={classnames(className)}
                options={attributeOptions}
                disabled={disabled}
                placeholder={placeholder}
                label={label as any}
                value={internalValue}
                disabledOptions={disabledOptions}
                onChange={handleChange}
                allowMultiple={true}
                maxSelections={maxSelections}
            />
        );
    }
);

export default AttributeSelect;

AttributeSelect.displayName = 'AttributeSelect';
