import React, { forwardRef, ReactNode, useEffect, useState, useCallback } from 'react';
import { ByzzerChangeEventHandler, ByzzerSearch, WithValue } from '@byzzer/ui-components';
import {useTenantApi} from '@/hooks/useTenantApi';
import classnames from 'classnames';
import { useApp } from '@/contexts/UserContext';

const baseClassName = 'omni-brand-family-search';

export type OmniBrandFamilySearchProps = {
    value?: string[];
    className?: string;
    name?: string;
    label?: ReactNode;
    onChange?: ByzzerChangeEventHandler<string[]>;
    placeholder?: string;
    maxBrandsFamily?: number; //decides how many brands we can add in dropdown
    disabled?: boolean;
    omniCategories?: string[];
} & OnlyRenderableIf;

export const OmniBrandFamilySearch = forwardRef<WithValue<string[]>, OmniBrandFamilySearchProps>(
    (
        {
            className,
            label,
            name,
            onChange,
            value,
            placeholder = 'Search For Omni Brands Family',
            maxBrandsFamily,
            disabled,
            onlyRenderIf = true,
            omniCategories,
            ...props
        },
        ref
    ) => {
        if (onlyRenderIf === false) return <></>;
        const { findOmniBrandsFamilyInCategories } = useTenantApi();
        const { omniCategories: omniCategoriesFromSubscription}  = useApp();
        const omniCategoriesList = omniCategories ?? omniCategoriesFromSubscription;
        const [internalValue, setInternalValue] = useState<string[]>();

        useEffect(() => {
            setInternalValue(value);
        }, [value])

        const findBrandsFamilyForOmni = useCallback(async (searchText: string): Promise<{brandsfamily: string[]}> => {
            const { brandFamilies } = await findOmniBrandsFamilyInCategories({
                categories:omniCategoriesList, 
                searchTerm: searchText
            }); // todo - recommend in Alby, use context categories vs select categories flag.
            return brandFamilies;
        }, [omniCategoriesList]);

        const handleChange = (e: any) => {
            const newBrandFamilyPayload = {
                value: e.value.map((brandFamily) => brandFamily.value),
                name,
                data: e.data
            }
            if (onChange) {
                onChange?.(newBrandFamilyPayload)
            } else {
                setInternalValue(e.data)
            }
        }

        return (
            <ByzzerSearch
                ref={ref}
                name={name}
                className={classnames(baseClassName, className)}
                search={findBrandsFamilyForOmni}
                onChange={handleChange}
                value={internalValue}
                label={label as any}
                placeholder={placeholder}
                maxSelections={maxBrandsFamily}
                disabled={disabled}
            />
        );
    }
);
export default OmniBrandFamilySearch;

OmniBrandFamilySearch.displayName = 'OmniBrandFamilySearch';